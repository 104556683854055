import { Component, Input, OnInit } from '@angular/core';
import { AccessoriesService } from 'src/app/services/Accessories/accessory.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { EventService } from 'src/app/services/Events/events.service';

@Component({
  selector: 'kits',
  templateUrl: './kits.component.html',
  styleUrls: ['./kits.component.scss'],
})
export class KitsComponent implements OnInit {
  @Input() kits: any;
  viewingAccessory: any;
  constructor(
    public accessoriesService: AccessoriesService,
    public globalService: GlobalService,
    private events: EventService
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }
  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'resetToDefault':
        if (this.viewingAccessory) this.viewingAccessory.viewAccessory = false;
        break;
      default:
        break;
    }
  }
  addPack(pack, accessoriesOverlapping, packsOverlapping) {
    if (accessoriesOverlapping.length > 0) {
      this.accessoriesService.handleOverlappingAccessoriesInPack(
        pack,
        accessoriesOverlapping,
        packsOverlapping,
        'accessoryOverlapping'
      );
    } else if (packsOverlapping.length > 0) {
      this.accessoriesService.handleOverlappingAccessoriesInPack(
        pack,
        accessoriesOverlapping,
        packsOverlapping,
        'packageOverlapping'
      );
    } else {
      this.accessoriesService.addPack(pack);
      return;
    }
  }
  viewDetails(kit) {
    for (let index = 0; index < this.kits.length; index++) {
      if (this.kits[index].packageId != kit.packageId) {
        this.kits[index].viewDetails = false;
      }
    }
    kit.viewDetails = !kit.viewDetails;
  }
  viewAccessoryFromPack(accessory) {
    if (this.viewingAccessory) this.viewingAccessory.viewAccessory = false;
    if (accessory.is_3d == 'yes') {
      accessory.viewAccessory = true;
      this.viewingAccessory = accessory;
      this.events.publish('events', { view3DAccessory: accessory });
    } else if (accessory.is_3d == 'no') {
      accessory.viewAccessory = true;
      this.viewingAccessory = accessory;
      this.events.publish('events', { addImageAccessory: accessory });
    }
  }
  exitView(accessory) {
    accessory.viewAccessory = false;
    this.viewingAccessory = accessory;
    this.events.publish('events', { resetToDefault: null });
  }
}
