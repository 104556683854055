import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  allConfigs: any = [];
  pageCount = 1;
  totalPages = 1;
  searchResult = false;
  constructor(
    private apiService: ApiService,
    private widgetsService: WidgetsService,
    private cachingService: CachingService,
    public globalService: GlobalService,
    private events: EventService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.globalService.activeTab = 'Profile';
    this.getCustomerInfo();
  }

  async getCustomerInfo() {
    if (this.globalService.customerInfo) {
      this.getAllConfigs();
    }
  }

  // async getConfigurations() {
  //   let configs = await this.cachingService.getDataFromSession('user_configurations');
  //   if (configs) {
  //     this.allConfigs = configs;
  //   } else {
  //     this.getAllConfigs();
  //   }
  // }

  //to call configuration api to get all details
  getAllConfigs() {
    this.pageCount = 1;
    let requestBody = {
      mobile_number: this.globalService.customerInfo.mobile_number,
      page: this.pageCount,
    };
    this.widgetsService.showLoader();
    this.apiService.post('configuration/all', requestBody).then(
      async (res: any) => {
        this.widgetsService.hideLoader();
        if (res.msg == 'success') {
          this.searchResult = true;
          // store the all config details
          this.totalPages = res.response.pages;
          // console.log('this.totalPages', this.totalPages);
          // console.log('this.pageCount', this.pageCount);

          this.allConfigs = res.response.configurations;
          await this.cachingService.setDataInSession(
            'user_configurations',
            this.allConfigs
          );
        } else if (res.msg == 'fail' && typeof res.response == 'object') {
          // store the all config details
          this.allConfigs = res.response.configurations;
          await this.cachingService.setDataInSession(
            'user_configurations',
            this.allConfigs
          );
        } else {
          this.widgetsService.toastWithButtons(
            typeof res.response == 'string'
              ? res.response
              : 'Something went wrong!',
            'danger'
          );
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons('Something went wrong!', 'danger');
      }
    );
  }
  loadMore() {
    this.pageCount =
      this.totalPages == 1 ? (this.pageCount = 1) : this.pageCount + 1;

    let requestBody = {
      mobile_number: this.globalService.customerInfo.mobile_number,
      page: this.pageCount,
    };
    this.widgetsService.showLoader();
    this.apiService.post('configuration/all', requestBody).then(
      (res: any) => {
        this.widgetsService.hideLoader();
        if (res.msg == 'success') {
          this.allConfigs.push(...res.response.configurations);
          // console.log(this.allConfigs);
          this.totalPages = res.response.pages;
        } else {
          this.pageCount = this.pageCount - 1;
        }
      },
      (err) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(
          this.globalService.apiErrorMsg,
          'danger'
        );
      }
    );
  }

  async createNewConfiguration() {
    let response = await this.cachingService.getDataFromSession('variants');
    await this.cachingService.setDataInSession('oldConfiguration', false);
    await this.cachingService.setDataInSession(
      'oldConfigurationFromSession',
      false
    );
    let selectedVariant = response.variants.filter((variant) => {
      return variant.default_variant == 'yes';
    })[0];
    this.events.publish('events', { newConfiguration: selectedVariant });
    this.globalService.getVariantDetails(selectedVariant, 'showLoader');
    this.closeProfilePanel();
    this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'new config');
  }

  async modalToConfirmNewConfiguration() {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'CREATE NEW CONFIGURATION',
          content: 'Existing configuration found. Do you want to create new ?',
          cancelText: 'Cancel',
          confirmText: 'Create New',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    data ? this.createNewConfiguration() : null;
  }

  saveCurrentConfiguration() {
    this.globalService.openBuildNameModal('save');
  }

  async modalToConfirmEditConfiguration(config) {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'EDIT CONFIGURATION',
          content:
            'Are you sure you want to change the build? Your current configuration will be reset.',
          cancelText: 'Cancel',
          confirmText: 'Confirm',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    data ? this.editConfiguration(config) : null;
  }

  async editConfiguration(config) {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile Edit'
    );
    let response = await this.cachingService.getDataFromSession('variants');
    await this.cachingService.setDataInSession('pin_no', config.pin_no);
    this.apiService
      .post('configuration/details', { pin_no: config.pin_no })
      .then(async (res: any) => {
        await this.cachingService.setDataInSession('oldConfiguration', true);
        let configuration = res.response.configurations[0];
        let variants = response.variants;
        let selectedVariant = variants.filter((variant) => {
          return variant.variant_id == configuration.variant_id;
        })[0];

        this.globalService.variant_color_id = configuration.variant_color_id;
        this.globalService.buildName = configuration.build_name;
        this.globalService.addedAccessories = configuration.accessories;
        this.globalService.addedAccessoriesPacks = configuration.package;
        this.events.publish('events', {
          loadOldConfiguration: selectedVariant,
        });
        this.globalService.getVariantDetails(selectedVariant, 'showLoader');
        this.closeProfilePanel('edit');
        this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'old config');
      });
  }

  downloadPDF() {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile PDF download'
    );
  }
  deleteConfiguration(config) {
    // not integrated, remove delete icon for now after confirmation
  }

  async logout() {
    this.cachingService.removeDataFromLocal('userDetails');
    this.cachingService.removeDataFromLocal('visitor_id');
    this.cachingService.removeDataFromSession('pin_no');
    this.globalService.configuration('create');
    this.globalService.customerInfo = null;
    // this.events.publish('events', { viewProfile: false });
    this.modalController.dismiss();
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile logout'
    );
  }

  closeProfilePanel(isEdit?) {
    this.modalController.dismiss(isEdit ? 'edit' : null);
    this.globalService.activeTab = 'accessories';
    this.events.publish('events', { viewProfile: false });
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'User profile close'
    );
  }
}
