import { Component } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { NetworkConnectivityComponent } from './components/network-connectivity/network-connectivity.component';
import { OrientationModalComponent } from './components/orientation-modal/orientation-modal.component';
import { EventService } from './services/Events/events.service';
declare global {
  interface Window {
    visualizer: Boolean;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  orientationError = false;
  browserNotSupported = false;
  orientationErrorType: any;
  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private events: EventService
  ) {
    if (this.platform.is('cordova')) {
      alert('CORDOVA');
    }
    this.orientationCheck();
    let browser = this.checkBrowser();
    this.browserControl(browser);
    window.addEventListener(
      'load',
      () => {
        if (navigator.onLine) {
          this.updateConnectionStatus('Online Load', true);
        } else {
          this.updateConnectionStatus('Offline', false);
        }
      },
      false
    );
    window.addEventListener(
      'online',
      () => {
        this.updateConnectionStatus('Online', true);
      },
      false
    );

    window.addEventListener(
      'offline',
      () => {
        this.updateConnectionStatus('Offline', false);
      },
      false
    );
  }

  async updateConnectionStatus(msg: string, connected: boolean) {
    if (msg == 'Offline' && !connected) {
      const modal = await this.modalController.create({
        component: NetworkConnectivityComponent,
        cssClass: 'one-3d-full-page-modal',
        backdropDismiss: false,
        animated: false,
      });
      return await modal.present();
    } else if (msg == 'Online Load') {
      return;
    } else {
      this.modalController.dismiss();
    }
  }
  async orientationCheck() {
    let device = await this.platform.platforms();
    if (window.innerHeight > window.innerWidth && device[0] == 'ipad') {
      // if (this.platform.is('cordova')) {
      this.openModalForOrientation('ipad');
      this.orientationErrorType = 'ipad';
      // }
    }
    window.addEventListener(
      'orientationchange',
      () => {
        // if (this.platform.is('cordova')) {
        if (window.orientation == 90) {
          if (device.includes('ipad')) {
            this.closeOrientationModal();
          } else if (device.includes('iphone') || device.includes('android')) {
            this.openModalForOrientation('mobile');
            this.orientationErrorType = 'mobile';
          }
        } else {
          let device = this.platform.platforms();
          if (device.includes('ipad')) {
            this.openModalForOrientation('ipad');
            this.orientationErrorType = 'ipad';
          } else if (device.includes('iphone') || device.includes('android')) {
            this.closeOrientationModal();
          }
        }
        // }
      },
      false
    );
  }
  async openModalForOrientation(orientation) {
    this.orientationError = true;
  }
  closeOrientationModal() {
    this.events.publish('events', {
      resize: null,
    });
    this.orientationError = false;
  }
  checkBrowser() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }
  browserControl(browser) {
    if (
      browser.name == 'IE' ||
      (browser.name == 'Safari' && browser.version < 14) ||
      (browser.name == 'Chrome' && browser.version < 90) ||
      (browser.name == 'Firefox' && browser.version < 90) ||
      browser.name == 'Opera'
    ) {
      this.browserNotSupported = true;
    }
  }
}
