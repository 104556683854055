// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  encryptionPassword: 'UcK*hCW(7qcf{N3d',

  // development
  // apiUrl: 'https://dev-api-jeep-japan.one3d.in/',
  // assetsUrl: '/assets/',

  // staging
  apiUrl: 'https://stg-api-jeep-japan.one3d.in/',
  assetsUrl: 'https://d2q8mxzhzk7c9f.cloudfront.net/',

  // production
  // apiUrl: 'https://api-japan-prod.one3d.in',
  // assetsUrl: 'https://d1tufaw5d51v07.cloudfront.net/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
