import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'app-key-features-modal',
  templateUrl: './key-features-modal.component.html',
  styleUrls: ['./key-features-modal.component.scss'],
})
export class KeyFeaturesModalComponent implements OnInit {
  @Input() variants: any;
  @Input() infoForVariant: any;
  @Input() selectedVariant: any;
  variantInfo: any;
  modelDetails: any;
  currentIndex: any;
  constructor(
    private modalController: ModalController,
    private cachingService: CachingService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    this.getModelDetails();
    this.showInfoForVariant(this.infoForVariant);
  }
  async getModelDetails() {
    this.modelDetails = await this.cachingService.getDataFromSession(
      'model_details'
    );
  }

  showInfoForVariant(variant) {
    this.variantInfo = variant;
    this.getCurrentIndex();
  }
  next() {
    this.variantInfo = this.variants[this.currentIndex + 1];
    this.getCurrentIndex();
  }
  prev() {
    this.variantInfo = this.variants[this.currentIndex - 1];
    this.getCurrentIndex();
  }
  getCurrentIndex() {
    this.currentIndex = this.variants.findIndex((el) => {
      return el.variant_id == this.variantInfo.variant_id;
    });
  }

  close() {
    this.modalController.dismiss();
  }
}
