import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';

@Component({
  selector: 'app-amc',
  templateUrl: './amc.component.html',
  styleUrls: ['./amc.component.scss'],
})
export class AmcComponent implements OnInit {
  allOptionCategory: any;
  constructor(
    private modalController: ModalController,
    private widgetService: WidgetsService,
    private apiService: ApiService,
    private cachingService: CachingService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    this.getAMC();
  }
  async getAMC() {
    let modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );
    let requestBody = {
      model_id: modelSelected.model_id,
      vehicle_age: 30,
      type: 'AMC',
    };

    this.widgetService.showLoader();
    this.apiService.post('configuration/extended-warranty', requestBody).then(
      async (res: any) => {
        this.widgetService.hideLoader();
        if (res.msg == 'success') {
          this.allOptionCategory = res.response;
          console.log(this.allOptionCategory);
        } else {
          this.widgetService.toastWithButtons(
            typeof res.response == 'string'
              ? res.response
              : 'Something went wrong!',
            'danger'
          );
        }
      },
      (error) => {
        this.widgetService.hideLoader();
        this.widgetService.toastWithButtons('Something went wrong!', 'danger');
      }
    );
  }
  closeAMC() {
    this.modalController.dismiss();
  }
  addToSummary() {
    let amc_details = {
      product_year: this.allOptionCategory[0]?.optionsList[0].product_year,
      product_kms: this.allOptionCategory[0]?.optionsList[0].product_kms,
      AnnualMaintenanceContractPrice:
        this.allOptionCategory[0]?.optionsList[0].warranty_price,
      VehicleAgeandKmDrivenAMC:
        this.allOptionCategory[0]?.optionsList[0].sales_period +
        ' & ' +
        this.allOptionCategory[0]?.optionsList[0].product_options,
    };

    this.cachingService.setDataInSession('amc_details', amc_details);
    this.modalController.dismiss('success');

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'add extended warranty to summary'
    );
  }
}
