import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-introduction-tutorial',
  templateUrl: './introduction-tutorial.component.html',
  styleUrls: ['./introduction-tutorial.component.scss'],
})
export class IntroductionTutorialComponent implements OnInit {
  constructor(private modalController: ModalController) {}

  ngOnInit() {}
  close() {
    this.modalController.dismiss();
  }
}
