import { Component, OnInit } from '@angular/core';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';

@Component({
  selector: 'emi',
  templateUrl: './emi.component.html',
  styleUrls: ['./emi.component.scss'],
})
export class EmiComponent implements OnInit {
  rateOfInterest = 8; //annual interest rate
  principalLoanAmount: any;
  exShowroomPrice: any;
  showError = false;
  variantName: any;

  EMI: any; // calculated EMI
  emiDetails = {
    tenureMonths: 60, // loan months/tenure/ duration in number of months
    downPayment: 0,
  };

  constructor(
    private apiService: ApiService,
    private cachingService: CachingService,
    public globalService: GlobalService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.getConfigDetails();
    this.calculateEMI();
  }

  async getConfigDetails() {
    let config_details = await this.cachingService.getDataFromSession('config');

    this.variantName = config_details.selectedVariant.version_name;
    this.exShowroomPrice = config_details.colorSelected.exshowroom_price;
    let emi = await this.cachingService.getDataFromSession('emi_details');
    if (emi?.EstimatedMonthlyPayment) {
      this.emiDetails.downPayment = Number(emi.DownPayment);
      this.emiDetails.tenureMonths = Number(emi.TermDuration.split(' ')[0]);
    } else {
      this.emiDetails.downPayment = Number(this.exShowroomPrice) / 10; // downPayment initially set to 10% of exShowroom price
    }
    // if (this.exShowroomPrice == 0) {
    //   this.exShowroomPrice = 2500000; //remove when legit price is coming from api
    // }
    this.calculateEMI();
  }

  calculateEMI() {
    this.emiDetails.tenureMonths > 84
      ? (this.emiDetails.tenureMonths = 84)
      : this.emiDetails.tenureMonths;

    if (Number(this.exShowroomPrice) > Number(this.emiDetails.downPayment)) {
      this.showError = false;
      this.principalLoanAmount = Number(
        this.exShowroomPrice - this.emiDetails.downPayment
      ); // calculate principal loan amount

      let perMonthROI = Number(this.rateOfInterest) / (12 * 100); // rate of interest is calculated on monthly basis

      // EMI calculation formula
      this.EMI = Math.ceil(
        Number(
          (this.principalLoanAmount *
            perMonthROI *
            Math.pow(1 + perMonthROI, Number(this.emiDetails.tenureMonths))) /
            (Math.pow(1 + perMonthROI, Number(this.emiDetails.tenureMonths)) -
              1)
        )
      );
    } else {
      this.showError = true;
      this.principalLoanAmount = 0;
      this.EMI = 0;
    }
  }

  addToSummary() {
    let emi_details = {
      ExShowroomPrice: this.exShowroomPrice,
      DownPayment: this.emiDetails.downPayment,
      TradeInValue: null,
      EstimatedAmountFinanced: this.principalLoanAmount,
      TermDuration: this.emiDetails.tenureMonths + ' Months',
      AnnualPercentageRate: this.rateOfInterest,
      EstimatedMonthlyPayment: this.EMI,
    };
    this.cachingService.setDataInSession('emi_details', emi_details);
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'add EMI to summary'
    );
    this.modalController.dismiss('success');
  }

  closeEMI() {
    // this.events.publish('events', { closeEMI: null });
    this.modalController.dismiss();
  }
  skipEMI() {
    this.modalController.dismiss();
    this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'skip EMI');
  }
}
