import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { EventService } from 'src/app/services/Events/events.service';
import { LoginComponent } from '../login/login.component';
import { ProfileComponent } from '../profile/profile.component';
import { ApiService } from 'src/app/services/API/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'configurator-header',
  templateUrl: './configurator-header.component.html',
  styleUrls: ['./configurator-header.component.scss'],
})
export class ConfiguratorHeaderComponent implements OnInit {
  viewProfile: boolean = false;
  loginClicked = false;
  subscription: Subscription;
  @Output() editConfig = new EventEmitter();
  constructor(
    private modalController: ModalController,
    public globalService: GlobalService,
    private cachingService: CachingService,
    private events: EventService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.getCustomerInfo();
    this.subscription = this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
    // setTimeout(() => {
    //   if (!this.loginClicked && !this.globalService.customerInfo) {
    //     console.log('timer');
    //     const audio = new Audio('assets/sounds/notification.mp3');
    //     audio.play();
    //     this.openLoginModal('autoPopup');
    //   }
    // }, 60000);
  }
  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'showLoginAutoPopup':
        setTimeout(() => {
          console.log('auto');

          if (!this.loginClicked && !this.globalService.customerInfo) {
            const audio = new Audio('assets/sounds/notification.mp3');
            audio.play();
            // this.events.publish('events', { resetToDefault: null });
            this.openLoginModal('autoPopup');
          }
        }, 10000);
        break;
      default:
        break;
    }
  }

  async getCustomerInfo() {
    this.globalService.customerInfo =
      await this.cachingService.getDataFromLocal('userDetails');
  }

  async openLoginModal(autoPopup?) {
    this.subscription.unsubscribe();
    this.loginClicked = true;
    let autoPopCss = '';
    if (autoPopup) {
      autoPopCss = 'one3d-login-modal-auto-pop';
    }
    const modal = await this.modalController.create({
      component: LoginComponent,
      componentProps: {
        contentData: {
          header: 'LOGIN',
          msgContent:
            'Logging in will help you retrieve information anywhere, anytime.',
          from: 'header',
        },
      },
      backdropDismiss: false,
      // cssClass: 'one3d-login-modal auto-height',
      cssClass: 'one3d-login-modal ' + autoPopCss + ' auto-height',
      animated: false,
    });
    this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'Login');

    await modal.present();
  }

  // openProfilePanel() {
  //   this.events.publish('events', { viewProfile: true });
  // }
  async openProfilePanel() {
    this.events.publish('events', { resetToDefault: null });
    if (this.globalService.activeTab != 'Profile') {
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Redirection::Internal',
        'User profile'
      );
      const isModalOpened = await this.modalController.getTop();
      if (isModalOpened) {
        this.modalController.dismiss();
      }
      const modal = await this.modalController.create({
        component: ProfileComponent,
        backdropDismiss: false,
        animated: false,
        cssClass: 'one3d-sidePanelModal-summary',
      });
      this.events.publish('events', { viewProfile: true });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.editConfig.emit('edit');
      }
      const modalOpened = await this.modalController.getTop();
      if (modalOpened) {
        this.modalController.dismiss();
      }
      this.globalService.activeTab = 'accessories';
    }
  }

  redirectToJeep() {
    window.open('https://www.jeep-japan.com/', '_self');
  }
}
