import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { ApiService } from '../API/api.service';
import { EventService } from '../Events/events.service';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root',
})
export class AccessoriesService {
  updatedAccessoryPrice: any;
  accessoriesAdded = [];
  packsAdded = [];
  constructor(
    private globalService: GlobalService,
    private modalController: ModalController,
    private events: EventService,
    private apiService: ApiService,
    private platform: Platform
  ) {}
  /**
   * @returns
   * The code is trying to calculate the total price of all accessories added by the user.
   * It starts with a global service that has an array called "addedAccessories".
   * Then it calculates the total number of packs added.
   * Finally, it adds up both sets of values to get the final value.
   * The code will return the updated price of all accessories and packs.
   */
  updateAccessoryPrice() {
    let accessoriesTotal = this.globalService.addedAccessories.reduce(
      (a, b) => Number(a) + Number(b['price'] || 0),
      0
    );

    let packsTotal = this.globalService.addedAccessoriesPacks.reduce(
      (a, b) => Number(a) + Number(b['pack_price'] || 0),
      0
    );
    let totalAccessoriesPrice = accessoriesTotal + packsTotal;
    let updatedPrice = {
      accessories: accessoriesTotal,
      packs: packsTotal,
      total: totalAccessoriesPrice,
    };
    return updatedPrice;
  }

  /**
   * @param accessory
   * @param from
   * The code starts by checking if the accessory is being added from an addon.
   * The code then checks if the from parameter is set to 'addons'.
   * If not, then the code filters out all accessories that are overlapping and remove from 3D.
   * If it's not, then the code will check to see if the accessory has a 3D model and publish events accordingly.
   * The code adds an accessory.
   */
  addAccessory(accessory, from?) {
    let addAccessory = {
      accessories_id: accessory.accessories_id,
      category: accessory.category,
      accessory_type: 'Accessory',
      marketing_name: accessory.marketing_name,
      price: accessory.price,
      quantity: accessory.quantity,
      subgroup_id: accessory.subgroup_id,
      hotspot_name: accessory.hotspot_name,
      is_3d: accessory.is_3d,
      mesh_code: accessory.mesh_code,
      tag_name: accessory.tag_name,
    };
    if (from != 'addons') {
      this.globalService.addedAccessories =
        this.globalService.addedAccessories.filter((el) => {
          if (el.subgroup_id == accessory.subgroup_id) {
            console.log("remove accessory condition in add accessory", el);
            el.is_3d == "yes" 
              ? this.events.publish('events', { removeAccessory: el })
              : this.events.publish('events', { removeImageAccessory: el })
          }
          return el.subgroup_id != accessory.subgroup_id;
        });
      this.accessoriesAdded = this.accessoriesAdded.filter((el) => {
        return el.subgroup_id != addAccessory.subgroup_id;
      });
      console.log('accessoriesAdded: ', this.accessoriesAdded);
    }
    if (accessory.is_3d == 'yes') {
      this.events.publish('events', { addAccessory: accessory });
    } else {
      this.events.publish('events', { addImageAccessory: accessory });
    }
    this.globalService.addedAccessories.push(addAccessory);
    this.globalService.setConfigData().then((res) => {
      this.globalService.configuration('update');
    });
    this.accessoriesAdded.push(addAccessory);
    const unique = [
      ...new Set(this.accessoriesAdded.map((item) => item.accessories_id)),
    ];
    console.log('uniqueFeatures o if ', unique);
    if (unique.length == 4) {
      console.log('uniqueFeatures', unique);
      // commented for jeep-japan
      // this.events.publish('events', { showLoginAutoPopup: null });
    }
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Accessories',
      accessory.category + ' ' + accessory.marketing_name
    );
  }
  removeAccessory(accessory, fromSummary?) {
    this.globalService.addedAccessories =
      this.globalService.addedAccessories.filter(
        (element) => element.accessories_id !== accessory.accessories_id
      );
    if (accessory.is_3d == 'yes' && !fromSummary) {
      this.events.publish('events', { removeAccessory: accessory });
    } else {
      this.events.publish('events', { removeImageAccessory: accessory });
    }

    this.globalService.setConfigData().then((res) => {
      this.globalService.configuration('update');
      // this.globalService.saveRemoveAccessory(accessory, 'Accessory');
    });
  }
  addPack(pack) {
    let addPack = {
      packageId: pack.packageId,
      accessory_type: 'Package',
      packageName: pack.packageName,
      pack_price: pack.pack_price,
      quantity: 1,
      accessoriesList: pack.accessoriesList,
      pack_mesh_code: pack.pack_mesh_code,
      hotspot_name: pack.hotspot_name,
      is_3d: pack.is_3d,
      mesh_code: pack.mesh_code,
      tag_name: pack.tag_name,
    };
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Accessories',
      'Package ' + pack.packageName
    );
    console.log(pack);

    let packHas3DAccessories = pack.accessoriesList.filter((el) => {
      return el.is_3d == 'yes';
    });
    console.log(packHas3DAccessories);
    if (packHas3DAccessories.length > 0) {
      this.events.publish('events', { addPack: pack });
    }
    this.globalService.addedAccessoriesPacks.push(addPack);
    this.globalService.setConfigData().then((res) => {
      this.globalService.configuration('update');
      // this.globalService.saveRemoveAccessory(addPack, 'Package');
    });
    this.packsAdded.push(pack.packageId);
    let uniqueFeatures = [...new Set(this.accessoriesAdded)];
    if (uniqueFeatures.length == 2) {
      // commented for jeep-japan
      // this.events.publish('events', { showLoginAutoPopup: null });
    }
  }
  removePack(pack) {
    this.globalService.addedAccessoriesPacks =
      this.globalService.addedAccessoriesPacks.filter(
        (element) => element.packageId !== pack.packageId
      );
    this.events.publish('events', { removePack: pack });
    this.globalService.setConfigData().then((res) => {
      this.globalService.configuration('update');
      // this.globalService.saveRemoveAccessory(pack, 'Package');
    });
  }

  accessoryAdded(accessory) {
    return this.globalService.addedAccessories.some((element) => {
      return element.accessories_id === accessory.accessories_id;
    });
  }
  packAdded(pack) {
    return this.globalService.addedAccessoriesPacks.some((element) => {
      return element.packageId === pack.packageId;
    });
  }

  ifAccessoryAddedInPack(accessory) {
    let accInPack = [];
    this.globalService.addedAccessoriesPacks.some((element) => {
      let accPack = element.accessoriesList.filter((packAcc) => {
        return packAcc.subgroup_id == accessory.subgroup_id;
      })[0];
      accPack ? accInPack.push(accPack) : null;
    });
    return accInPack;
  }
  /**
   *
   * @param pack
   * The code returns accessoryFilter which contains only those items that are not filtered out from packAcc's accessoriesList
   * The code is used to determine if the pack has any accessories that overlap with another pack.
   * If it does, then the code iterates through all of the accessories in the accessory list and filters them based on their subgroup_id.
   * The result of this code is an array of objects representing an accessory from the pack.
   * @returns
   */
  ifPackOverlaps(pack) {
    let accessoryFilter = [];
    this.globalService.addedAccessoriesPacks.some((element) => {
      pack.accessoriesList.filter((packAcc) => {
        let filter = element.accessoriesList.filter((addedAcc) => {
          return packAcc.subgroup_id == addedAcc.subgroup_id;
        });
        filter[0] ? accessoryFilter.push(filter[0]) : null;
      });
    });

    return accessoryFilter;
  }
  /**
   * @param pack
   * The code is trying to find all the accessories that are already added.
   * It does this by using a filter on the list of accessories and checking if they have an accessory with the same subgroup_id as what is being searched for, and also has a category that is not 'Addons'.
   * If there are any found, it adds them to an array called accessoryFilter.
   * If the pack does not have any accessories yet, then this function would iterate through all of the packs' subgroups and filter for each one to see if they have an accessory with a matching ID and category.
   * If so, it would add that accessory to the array and return it.
   * @returns
   */
  ifAccessoryAlreadyAdded(pack) {
    let accessoryFilter = [];
    this.globalService.addedAccessories.some((element) => {
      let filter = pack.accessoriesList.filter((acc) => {
        return (
          acc.subgroup_id == element.subgroup_id && element.category != 'Addons'
        );
      });
      filter.length > 0 ? accessoryFilter.push(element) : null;
    });
    return accessoryFilter;
  }
  async handleOverlappingAccessories(accessory, accessoryAddedInPack) {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'OVERLAPPING ACCESSORIES',
          content: `This accessory is already included in  ${accessoryAddedInPack.pack_name}. Do you want to replace?`,
          cancelText: 'CANCEL',
          confirmText: 'CONFIRM',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      this.handleAccessories(accessory);
    } else {
      return null;
    }
  }

  /**
   *
   * @param accessory
   * This function starts by creating an array called addedAccessoriesPacks which contains all of the packs that have been added so far.
   * Then it filters out any packs that are not related to this particular accessory (accessory).
   * Next, it filters out any packs from this list if they do not belong to this subgroup (subgroup_id)
   * The code attempts to filter the list of accessories that are added and remove any accessory that does not belong to the same subgroup as the current accessory.
   */
  handleAccessories(accessory) {
    this.globalService.addedAccessoriesPacks =
      this.globalService.addedAccessoriesPacks.filter((el) => {
        let packRemoved = false;
        el.accessoriesList.filter((packAcc) => {
          if (packAcc.subgroup_id != accessory.subgroup_id) {
            !packRemoved
              ? this.events.publish('events', { removePack: packAcc })
              : null;
            packRemoved = true;
          }
          return packAcc.subgroup_id == accessory.subgroup_id;
        });
      });
    this.addAccessory(accessory);
  }

  /**
   *
   * @param pack
   * @param accessoriesOverlapping
   * @param packsOverlapping
   * @param overlappingType
   * @returns
   * The code first checks to see if there are any packages overlapping by checking for an accessoryOverlapping or packageOverlapping event.
   * Then, depending on what type of overlap was found, the code prints out either a message saying "Pack A overlaps some accessories that is already added from Pack B's accessories" or "Pack A overlaps some accessories that is already added."
   * If there is data from this function call, then we can analyze what happened inside of our app.
   * The code is used to create a modal that allows the user to confirm or cancel the replacement of an accessory.
   */

  async handleOverlappingAccessoriesInPack(
    pack,
    accessoriesOverlapping,
    packsOverlapping,
    overlappingType
  ) {
    let contentMsg: string;
    if (overlappingType == 'accessoryOverlapping') {
      // contentMsg = `${pack.packageName} overlaps some accessories that is already added from ${packsOverlapping.category} accessories.`;
      contentMsg = `${pack.packageName} overlaps some accessories that is already added.`;
    } else if (overlappingType == 'packageOverlapping') {
      contentMsg = `${pack.packageName} is overlapping some accessories with ${packsOverlapping[0].pack_name}. Do you want to replace?`;
    }

    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'OVERLAPPING ACCESSORIES',
          content: contentMsg,
          cancelText: 'CANCEL',
          confirmText: 'CONFIRM',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      const key = 'pack_mesh_code';
      const arrayUniqueByKey = [
        ...new Map(packsOverlapping.map((item) => [item[key], item])).values(),
      ];
      for (let index = 0; index < arrayUniqueByKey.length; index++) {
        if (arrayUniqueByKey[index][key]) {
          this.events.publish('events', {
            removePack: arrayUniqueByKey[index],
          });
        }
        // console.log(arrayUniqueByKey[index][key]);
      }
      this.handlePack(pack);
    } else {
      return null;
    }
  }
  /**
   *
   * @param pack
   *  The code is trying to find the accessory that is added for a specific pack.
   * If it finds one, then it removes the accessory from added accessories list.
   * It will filter the accessories list for each pack, and if an accessory is found in both packs, it will remove the overlapping packs.
   */
  handlePack(pack) {
    pack.accessoriesList.filter((packAcc) => {
      this.globalService.addedAccessories =
        this.globalService.addedAccessories.filter((acc) => {
          // if (packAcc.subgroup_id == acc.subgroup_id) {
          //   this.events.publish('events', { removeAccessoryForPack: acc });
          // }
          if (acc.category == 'Addons') {
            return acc;
          } else {
            return packAcc.subgroup_id != acc.subgroup_id;
          }
        });
    });
    let addedAccessoriesPacks = this.globalService.addedAccessoriesPacks;
    if (addedAccessoriesPacks.length > 0) {
      let accessoryFilter = [];
      this.globalService.addedAccessoriesPacks.some((element) => {
        pack.accessoriesList.filter((packAcc) => {
          let filter = element.accessoriesList.filter((addedAcc) => {
            return packAcc.subgroup_id == addedAcc.subgroup_id;
          });
          filter[0] ? accessoryFilter.push(filter[0]) : null;
        });
      });
      const unique = [...new Set(accessoryFilter.map((item) => item.pack_id))];
      unique.filter((ele) => {
        this.globalService.addedAccessoriesPacks =
          this.globalService.addedAccessoriesPacks.filter((packs) => {
            return packs.packageId != ele;
          });
      });
    }
    this.addPack(pack);
  }
}
