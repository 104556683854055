import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes_vs: Routes = [
  {
    path: 'visualizer/:zipCode/:vehicleCode/:cpos/:optionalCodes',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer/:zipCode/:vehicleCode/:cpos',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer/:zipCode/:vehicleCode',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer/:zipCode',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },

  {
    path: '',
    redirectTo: 'visualizer',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'visualizer',
    pathMatch: 'full',
  },
];
const routes_cc: Routes = [
  {
    path: 'configurator/:zipCode/:vehicleCode/:cpos/:optionalCodes',
    loadChildren: () =>
      import('./pages/configuration/configuration.module').then(
        (m) => m.ConfigurationPageModule
      ),
  },
  {
    path: 'configurator/:zipCode/:vehicleCode/:cpos',
    loadChildren: () =>
      import('./pages/configuration/configuration.module').then(
        (m) => m.ConfigurationPageModule
      ),
  },
  {
    path: 'configurator/:zipCode/:vehicleCode',
    loadChildren: () =>
      import('./pages/configuration/configuration.module').then(
        (m) => m.ConfigurationPageModule
      ),
  },
  {
    path: 'configurator/:zipCode',
    loadChildren: () =>
      import('./pages/configuration/configuration.module').then(
        (m) => m.ConfigurationPageModule
      ),
  },
  {
    path: 'configurator',
    loadChildren: () =>
      import('./pages/configuration/configuration.module').then(
        (m) => m.ConfigurationPageModule
      ),
  },
  {
    path: '',
    redirectTo: 'configurator',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'configurator',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes_vs, { preloadingStrategy: PreloadAllModules }),
    // RouterModule.forRoot(routes_cc, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
