import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'microinteraction-panel',
  templateUrl: './microinteraction-panel.component.html',
  styleUrls: ['./microinteraction-panel.component.scss'],
})
export class MicrointeractionPanelComponent implements OnInit {
  microInteractions: any;
  showColors = false;
  backgroundEnv = 'day';
  backGroundImage = 'assets/img/icons/one3d-bg-icon-day.svg';
  toggleSunroof = false;
  toggleLights = false;
  toggleDoors = false;
  seatingView = 'frontSeatView';
  fromCallBack = false;
  sunroofAnimation = false;
  lightAnimation = false;
  doorAnimation = false;

  // tutorialVariables
  interactions = [];
  showTutorialForInterior = false;
  wentToInterior = false;
  selectedRowValue:any;

  constructor(
    private events: EventService,
    public globalService: GlobalService,
    private cachingService: CachingService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }

  async subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'firstLoadComplete':
        let variant = data.firstLoadComplete;
        this.manageDataForInteractions(variant.microInteractions);
        break;
      case 'selectedVariant':
        let selectedVariant = data.selectedVariant;
        this.manageDataForInteractions(selectedVariant.microInteractions);
        break;
      case 'view':
        switch (data.view.id) {
          case 'INT':
            this.globalService.viewInterior = true;
            this.seatingView = 'frontSeatView';
            this.selectedRowValue = await this.globalService.getTranslation("1ST_ROW");
            this.fromCallBack = false;
            this.showTutorialForInterior = false;
            break;
          case 'EXT':
            this.globalService.viewInterior = false;
            break;
          case 'INT_COMPLETE':
            this.globalService.viewInterior = true;
            this.seatingView = 'frontSeatView';
            this.selectedRowValue = await this.globalService.getTranslation("1ST_ROW");
            this.wentToInterior = true;
            break;
          case 'EXT_COMPLETE':
            this.globalService.viewInterior = false;
            break;
          default:
            break;
        }
        break;
      case 'frontSeatViewComplete':
        this.seatingView = 'frontSeatView';
        this.selectedRowValue = await this.globalService.getTranslation("1ST_ROW");
        this.apiService.visualizerAnalytics('seating_row_1');
        this.fromCallBack = true;
        break;
      case 'backSeatViewComplete':
        this.seatingView = 'backSeatView';
        this.selectedRowValue = await this.globalService.getTranslation("2ND_ROW");
        this.apiService.visualizerAnalytics('seating_row_2');
        this.fromCallBack = true;
        break;
      case 'lastSeatViewComplete':
        this.seatingView = 'lastSeatView';
        this.selectedRowValue = await this.globalService.getTranslation("3RD_ROW");
        this.apiService.visualizerAnalytics('seating_row_3');
        this.fromCallBack = true;
        break;
      case 'featureShowcase':
        this.turnOffInteractions();
        break;
      case 'sunroofStart':
        this.sunroofAnimation = true;
        break;
      case 'sunroofEnd':
        this.sunroofAnimation = false;
        break;
      case 'lightStart':
        this.lightAnimation = true;
        break;
      case 'lightEnd':
        this.lightAnimation = false;
        break;
      case 'doorStart':
        this.doorAnimation = true;
        break;
      case 'doorEnd':
        this.doorAnimation = false;
        break;
      case 'featureIsImage':
        this.resetMicroInteractions();
        break;
      case 'featureIs2Dvideo':
        this.resetMicroInteractions();
        break;
      default:
        break;
    }
  }
  manageDataForInteractions(microInteractions) {
    microInteractions.map((interaction) => {
      if (interaction.id == 1) {
        this.toggleSunroof = false;
        interaction.icon = 'assets/img/icons/one3d-sunroof-icon.svg';
      } else if (interaction.id == 2) {
        this.toggleLights = false;
        interaction.icon = 'assets/img/icons/one3d-lights-icon.svg';
      } else if (interaction.id == 3) {
        this.toggleDoors = false;
        interaction.icon = 'assets/img/icons/one3d-door-icon.svg';
      }
    });
    this.microInteractions = microInteractions;
  }

  microInteraction3DCall(interactionDetail) {
    switch (interactionDetail.id) {
      case 1:
        if (this.sunroofAnimation) return;
        this.interactions.push('sunroof');
        this.toggleSunroof = !this.toggleSunroof;
        if (this.toggleSunroof) {
          interactionDetail.icon = 'assets/img/icons/one3d-sunroof-yellow.svg';
          this.apiService.logGAEvent(
            'uaevent',
            'Content',
            'Click::Toolbar',
            'sunroof::on'
          );
        } else {
          interactionDetail.icon = 'assets/img/icons/one3d-sunroof-icon.svg';
          this.apiService.logGAEvent(
            'uaevent',
            'Content',
            'Click::Toolbar',
            'sunroof::off'
          );
        }
        this.events.publish('events', { toggleSunroof: null });
        this.checkForOpenTutorial();
        this.apiService.visualizerAnalytics('sunroof_menu');
        break;
      case 2:
        if (this.lightAnimation) return;
        this.interactions.push('lights');
        this.toggleLights = !this.toggleLights;
        if (this.toggleLights) {
          interactionDetail.icon = 'assets/img/icons/one3d-lights-yellow.svg';
          this.apiService.logGAEvent(
            'uaevent',
            'Content',
            'Click::Toolbar',
            'Lights::On'
          );
        } else {
          interactionDetail.icon = 'assets/img/icons/one3d-lights-icon.svg';
          this.apiService.logGAEvent(
            'uaevent',
            'Content',
            'Click::Toolbar',
            'Lights::Off'
          );
        }
        this.events.publish('events', { toggleLights: null });
        this.checkForOpenTutorial();
        this.apiService.visualizerAnalytics('light_menu');
        break;
      case 3:
        if (this.doorAnimation) return;
        this.interactions.push('doors');
        this.toggleDoors = !this.toggleDoors;
        if (this.toggleDoors) {
          interactionDetail.icon = 'assets/img/icons/one3d-door-yellow.svg';
          this.apiService.logGAEvent(
            'uaevent',
            'Content',
            'Click::Toolbar',
            'Door::Open'
          );
        } else {
          interactionDetail.icon = 'assets/img/icons/one3d-door-icon.svg';
          this.apiService.logGAEvent(
            'uaevent',
            'Content',
            'Click::Toolbar',
            'Door::Close'
          );
        }
        this.events.publish('events', { toggleDoors: null });
        this.checkForOpenTutorial();
        this.apiService.visualizerAnalytics('door_menu');
        break;
      default:
        break;
    }
  }

  resetMicroInteractions() {
    if (this.toggleLights) {
      this.toggleLights = !this.toggleLights;
      this.events.publish('events', { toggleLights: null });
    }
    if (this.toggleDoors) {
      this.toggleDoors = !this.toggleDoors;
      this.events.publish('events', { toggleDoors: null });
    }
    if (this.toggleSunroof) {
      this.toggleSunroof = !this.toggleSunroof;
      this.events.publish('events', { toggleSunroofQuick: null });
    }
  }

  async turnOffInteractions() {
    let variant = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    this.manageDataForInteractions(variant.microInteractions);
  }

  viewExterior() {
    if (this.globalService.viewInterior) {
      this.globalService.viewInterior = false;
      this.events.publish('events', { view: { id: 'EXT' } });
      this.apiService.logGAEvent('uaevent', 'Content', 'Click::Toolbar', 'ext');
      this.apiService.visualizerAnalytics('exterior_button');
    }
  }
  viewInterior() {
    if (!this.globalService.viewInterior) {
      this.globalService.viewInterior = true;
      this.events.publish('events', { view: { id: 'INT' } });
      this.apiService.logGAEvent('uaevent', 'Content', 'Click::Toolbar', 'int');
      this.apiService.visualizerAnalytics('interior_button');
    }
  }
  viewSeats(event, fromCallBack) {
    this.events.publish('events', {
      view: { id: 'SEAT', value: event.target.value },
    });
  }
  hideShowColorPallette() {
    this.interactions.push('colors');
    this.events.publish('events', { showColors: this.showColors });
    this.showColors = !this.showColors;
    this.checkForOpenTutorial();
    if (this.showColors) this.apiService.visualizerAnalytics('colors_menu');
  }
  backgroundChange() {
    this.interactions.push('background');

    if (this.backgroundEnv == 'day') {
      this.backgroundEnv = 'night';
      this.backGroundImage = 'assets/img/icons/one3d-bg-icon-night.svg';
      this.events.publish('events', { backgroundChange: { env: 'night' } });
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click::Toolbar',
        'Background::Night'
      );
    } else {
      this.backgroundEnv = 'day';
      this.backGroundImage = 'assets/img/icons/one3d-bg-icon-day.svg';
      this.events.publish('events', { backgroundChange: { env: 'day' } });
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click::Toolbar',
        'Background::Day'
      );
    }
    this.checkForOpenTutorial();
    this.apiService.visualizerAnalytics('backgrounds_menu');
  }
  async checkForOpenTutorial() {
    let interiorTutorialShown = await this.cachingService.getDataFromLocal(
      'i_t'
    );
    const unique = [...new Set(this.interactions.map((item) => item))];
    setTimeout(() => {
      if (
        unique.length == 2 &&
        !this.showTutorialForInterior &&
        !this.wentToInterior &&
        !interiorTutorialShown
      ) {
        const audio = new Audio('assets/sounds/notification.mp3');
        audio.play();
        this.showTutorialForInterior = true;
        this.cachingService.setDataInLocal('i_t', true);
        this.closeAfterTimeout();
      }
    }, 10000);
  }
  closeAfterTimeout() {
    setTimeout(() => {
      this.showTutorialForInterior = false;
      this.wentToInterior = true;
    }, 10000);
  }
  closeInteriorTutorial() {
    this.showTutorialForInterior = false;
    this.wentToInterior = true;
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'Close Interior tutorial'
    );
  }
}
