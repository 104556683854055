import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccessoriesService } from 'src/app/services/Accessories/accessory.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { EventService } from 'src/app/services/Events/events.service';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'summary-breakup',
  templateUrl: './summary-breakup.component.html',
  styleUrls: ['./summary-breakup.component.scss'],
})
export class SummaryBreakupComponent implements OnInit {
  accessoryPackages = [];
  modelSpecifications: any;
  extendedWarrantyCost: number;
  totalPrice: number;
  constructor(
    private events: EventService,
    private modalController: ModalController,
    private cachingService: CachingService,
    public accessoriesService: AccessoriesService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });

    this.getSpecifications();
  }

  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'updatedWarrantyCost':
        this.getExtendedWarrantyCost();
        break;

      default:
        break;
    }
  }
  
  async getSpecifications() {
    let config = await this.cachingService.getDataFromSession('config');
    let modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );
    this.accessoryPackages = config.addedAccessoriesPacks;

    this.modelSpecifications = [
      {
        label: await this.globalService.getTranslation('MODEL'),
        value: modelSelected.model_name,
        labelColSize: '6',
        valueColSize: '6',
        display: true,
      },
      {
        label: await this.globalService.getTranslation('VARIANT'),
        value: config.selectedVariant.version_name,
        labelColSize: '4',
        valueColSize: '8',
        display: true,
      },
      {
        label: await this.globalService.getTranslation('COLOR'),
        value: config.colorSelected.color_name,
        labelColSize: '2',
        valueColSize: '10',
        display: true,
      },
      {
        label: await this.globalService.getTranslation('COLOR PRICE'),
        value: config.colorSelected.color_price == 0 
          ? config.colorSelected.color_price + ' ' + await this.globalService.getTranslation('₹')
          : this.globalService.currencyFormatterIN(
            config.colorSelected.color_price
            ) + ' ' + await this.globalService.getTranslation('₹'),
        labelColSize: '6',
        valueColSize: '6',
        display: true,
      },
      {
        label: await this.globalService.getTranslation('TRANSMISSION & DRIVE'),
        value:
          config.selectedVariant.transmission_type +
          ' ' +
          config.selectedVariant.transmission_code,
        labelColSize: '8',
        valueColSize: '4',
        display: true,
      },
      {
        label: await this.globalService.getTranslation('EX-SHOWROOM PRICE'),
        value:
          this.globalService.currencyFormatterIN(
            config.colorSelected.exshowroom_price
          ) + ' ' + await this.globalService.getTranslation('₹') ,
        class: 'bold',
        labelColSize: '6',
        valueColSize: '6',
        display: true,
      },
      // {
      //   label: 'ADDED ACCESSORIES',
      //   value:
      //     '₹ ' +
      //     this.globalService.currencyFormatterIN(
      //       this.accessoriesService.updateAccessoryPrice().accessories
      //     ),
      //   class: 'bold',
      //   labelColSize: '6',
      //   valueColSize: '6',
      //   display: this.globalService.addedAccessories.length > 0 ? true : false,
      // },
    ];
    this.getExtendedWarrantyCost();
    // this.getTotalPrice();
  }

  async getTotalPrice() {
    let config = await this.cachingService.getDataFromSession('config');
    if (config.colorSelected.exshowroom_price == 0) {
      this.totalPrice = 0;
      return;
    } else {
      this.totalPrice =
        this.accessoriesService.updateAccessoryPrice().total +
        config.colorSelected.exshowroom_price +
        this.extendedWarrantyCost;
    }
  }

  async getExtendedWarrantyCost() {    
    let extendedWarranty = await this.cachingService.getDataFromSession(
      'extendedWarranty_details'
    );
    if (extendedWarranty?.warrantyPrice) {
      this.extendedWarrantyCost = extendedWarranty.warrantyPrice;
    } else {
      this.extendedWarrantyCost = 0;
    }
    this.getTotalPrice();
  }

  async removePackConfirmation(pack) {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'REMOVING PACK',
          content:
            'You are about to remove this pack from your configuration. Are you sure?',
          cancelText: 'CANCEL',
          confirmText: 'CONFIRM',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    data ? this.removePack(pack) : null;
  }
  removePack(pack) {
    this.accessoriesService.removePack(pack);
    this.accessoryPackages = this.globalService.addedAccessoriesPacks;
    this.getTotalPrice();
  }
}
