import { Component, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/Events/events.service';
import { FeatureShowcaseComponent } from '../feature-showcase/feature-showcase.component';

@Component({
  selector: 'feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss'],
})
export class FeatureComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true })
  accordionGroup: IonAccordionGroup;
  features: any;
  constructor(
    private modalController: ModalController,
    private events: EventService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }
  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'variantDetails':
        this.getFeatures(data.variantDetails);
        break;
      case 'variantFeatures':
        this.getFeatures(data.variantFeatures);
        break;
      default:
        break;
    }
  }
  async getFeatures(selectedVariant) {
    this.features = selectedVariant.features;
  }
  async playFeatures(feature, featureCategory) {
    const modal = await this.modalController.create({
      component: FeatureShowcaseComponent,
      componentProps: {
        selectedFeature: feature,
        selectedFeatureCategory: featureCategory,
      },
      cssClass: 'one3d-sidePanelModal',
      backdropDismiss: false,
      animated: false,
    });
    await modal.present();
  }
  openFeatureList() {
    if (this.accordionGroup.value) {
      this.apiService.visualizerAnalytics('feature_category');
    }
  }
}
