import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/Events/events.service';
import { CachingService } from './../../services/cache/caching.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-feature-showcase',
  templateUrl: './feature-showcase.component.html',
  styleUrls: ['./feature-showcase.component.scss'],
})
export class FeatureShowcaseComponent implements OnInit, OnDestroy {
  @Input() selectedFeature: any;
  @Input() selectedFeatureCategory: any;
  @Input() featureMesh: any;
  features: any;
  featuresLength: any;
  featureCategorySelected: any;
  currentIndex;
  isFeaturePlaying: boolean;
  categories: any[];
  featurePlayed = [];
  ambientSound = new Audio('assets/sounds/ambient_sound.mp3');
  muteMusic = false;
  subscription: Subscription;
  constructor(
    private modalController: ModalController,
    private cachingService: CachingService,
    private events: EventService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    if (this.selectedFeature) {
      this.getFeatureFromSelection();
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click::Action',
        this.selectedFeature?.feature_name
      );
    } else if (this.featureMesh) {
      this.getFeatureFromHotspot();
      this.apiService.visualizerAnalytics(this.featureMesh);
    }
    this.ambientSound.play();
    this.ambientSound.loop = true;
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Action',
      'Ambient Sound::On'
    );
  }

  ngOnDestroy(): void {
    this.ambientSound.pause();
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Action',
      'Ambient Sound::Off'
    );
  }
  muteAudio() {
    this.muteMusic = !this.muteMusic;
    this.ambientSound.muted = this.muteMusic;
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Action',
      'Ambient Sound::' + this.muteMusic ? 'Mute' : 'Unmute'
    );
  }
  getFeatureFromSelection() {
    this.getFeatures();
    this.subscription = this.events.subscribe('playNext', (data) => {
      this.subscribeToPlayEvents(data);
    });

    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
    this.events.publish('events', { featureShowcase: true });
  }
  async getFeatureFromHotspot() {
    let features = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    this.features = features.features;
    let selectedFeature = [];
    this.features.filter((feat) => {
      let feature = feat.featureList.filter((selectedFeat) => {
        return selectedFeat.hotspot_name == this.featureMesh;
      })[0];
      feature ? selectedFeature.push(feature) : null;
    });
    this.selectedFeature = selectedFeature[0];
    this.selectedFeatureCategory = this.selectedFeature.category_name;
    this.getFeatureFromSelection();
    this.events.publish('events', { featureShowcase: true });
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Hotspots',
      this.selectedFeature?.feature_name
    );
  }

  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'featureIsPlaying':
        this.isFeaturePlaying = data.featureIsPlaying;
        break;
      default:
        break;
    }
  }
  subscribeToPlayEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'playNext':
        this.playNext();
        break;
      default:
        break;
    }
  }

  playNext() {
    this.goToNextFeature();
  }

  async getFeatures() {
    let features = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    this.features = features.features;
    this.selectFeatureCategory(this.selectedFeatureCategory);
    this.getFeaturesCategories();
  }

  getFeaturesCategories() {
    this.categories = this.features.map((a) => a.featureCategory);
  }

  selectFeatureCategory(featureCategory) {
    this.events.publish('events', { featureDetails: this.selectedFeature });
    this.featurePlayed.push(this.selectedFeature.feature_id);

    this.featureCategorySelected = featureCategory;
    let selectedCategoryFeatures = this.features.filter(
      (el) => el.featureCategory == featureCategory
    )[0];
    this.featuresLength = selectedCategoryFeatures.featureList;
    this.currentIndex = selectedCategoryFeatures.featureList.findIndex(
      (e) => e.feature_id == this.selectedFeature.feature_id
    );
  }

  goToNextFeature() {
    let features = this.features.filter(
      (el) => el.featureCategory == this.featureCategorySelected
    )[0];
    this.selectedFeature = features.featureList[this.currentIndex + 1];
    if (this.currentIndex + 1 != features.featureList.length) {
      this.selectFeatureCategory(this.featureCategorySelected);
    } else {
      let categoryIndex = this.categories.findIndex(
        (a) => a == this.featureCategorySelected
      );
      console.log(this.features[categoryIndex + 1]);

      if (this.features[categoryIndex + 1]?.featureList[0]) {
        this.selectedFeature = this.features[categoryIndex + 1].featureList[0];
        this.selectFeatureCategory(this.categories[categoryIndex + 1]);
      } else {
        this.closeModal();
      }
    }
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Action',
      this.selectedFeature?.feature_name
    );
  }
  goToPreviousFeature() {
    // this.subscription.unsubscribe();
    // this.events.subscribe('events', (data) => {
    //   this.subscribeToEvents(data);
    // });
    let features = this.features.filter(
      (el) => el.featureCategory == this.featureCategorySelected
    )[0];
    this.selectedFeature = features.featureList[this.currentIndex - 1];
    if (this.currentIndex != 0) {
      this.selectFeatureCategory(this.featureCategorySelected);
    } else {
      let categoryIndex = this.categories.findIndex(
        (a) => a == this.featureCategorySelected
      );
      let lengthOfLastPlayedFeatures =
        this.features[categoryIndex - 1].featureList.length;
      this.selectedFeature =
        this.features[categoryIndex - 1].featureList[
          lengthOfLastPlayedFeatures - 1
        ];
      this.selectFeatureCategory(this.categories[categoryIndex - 1]);
    }
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Action',
      this.selectedFeature?.feature_name
    );
  }

  async closeModal() {
    let pin_no = await this.cachingService.getDataFromSession('p_vs');
    let uniqueFeatures = [...new Set(this.featurePlayed)];
    let requestBody = {
      pin_no: pin_no,
      features: uniqueFeatures,
    };
    this.apiService
      .post('configuration/visualizer/save-features', requestBody)
      .then(
        (res: any) => {
          if (res.msg == 'success') {
            this.modalController.dismiss();
          } else {
            this.modalController.dismiss();
          }
        },
        (err) => {
          this.modalController.dismiss();
        }
      );
    this.events.publish('events', { featureShowcase: false });
    this.events.publish('events', { featureDetails: null });
    this.events.publish('events', { closeFeatureShowCase: null });
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'Close button on features'
    );
    this.subscription.unsubscribe();

    // this.modalController.dismiss();
  }
}
