import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';

declare var window;
export enum Tabs {
  KITS = 'KITS',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
}
@Component({
  selector: 'accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss'],
})
export class AccessoriesComponent implements OnInit {
  @Input() mobile: any;
  @Output() close = new EventEmitter();
  segmentSelected: string = Tabs.KITS;
  segments = [];
  exteriorAccessories = [];
  interiorAccessories = [];
  kits = [];
  constructor(
    private events: EventService,
    public globalService: GlobalService,
    private cachingService: CachingService,
    private apiService: ApiService
  ) {}

  get Tabs() {
    return Tabs;
  }

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
    if (this.mobile == 'mobile') {
      this.getDataForMobile();
    }
  }

  async getDataForMobile() {
    let variantDetails = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    this.getAccessories(variantDetails);
  }

  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'variantDetails':
        this.getAccessories(data.variantDetails);
        break;
      case 'view':
        switch (data.view.id) {
          case 'INT':
            this.segmentSelected = Tabs.INTERIOR;
            break;
          case 'EXT':
            this.segmentSelected = Tabs.EXTERIOR;
            break;
          case 'INT_COMPLETE':
            this.segmentSelected = Tabs.INTERIOR;

            // this.apiService.logGAEvent(
            //   'uaevent',
            //   'Content',
            //   'Click::Accessories',
            //   'int'
            // );

            break;
          case 'EXT_COMPLETE':
            this.segmentSelected = Tabs.EXTERIOR;

          // this.apiService.logGAEvent(
          //   'uaevent',
          //   'Content',
          //   'Click::Accessories',
          //   'ext'
          // );
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
 * To find the count of total accessories in particular tab(Ext/Int)
 * @param subgroups List of Accessories(ext/int)
 * @returns total acc count
 */
  countAccessoriesLength(subgroups) {
    let count = 0;
    for (let sg of subgroups) {
        count += sg.subgroupAccessories.length;
    }
    return count;
  }
  
  async getAccessories(variant) {
    variant.packages.map((pack) => {
      pack.viewDetails = false;
    });
    this.kits = variant.packages;
    if (this.kits.length > 0) {
      for (let index = 0; index < this.kits.length; index++) {
        this.kits[index].accessoriesList.map((el) => {
          el.viewAccessory = false;
        });
      }
    }
    console.log('this.kits', this.kits);
    let accessories = variant.accessories;
    for (let index = 0; index < accessories.length; index++) {
      if (accessories[index].name.toLowerCase().includes('interior')) {
        this.interiorAccessories = accessories[index].subGroups;
        this.interiorAccessories.map((el) => {
          el.viewDetails = false;
        });
      } else if (accessories[index].name.toLowerCase().includes('exterior')) {
        this.exteriorAccessories = accessories[index].subGroups;
        this.exteriorAccessories.map((el) => {
          el.viewDetails = false;
        });
      }
    }
    if (this.kits.length == 0) {
      this.segments = [
        {
          id: Tabs.EXTERIOR,
          label: `${await this.globalService.getTranslation(Tabs.EXTERIOR)} (${this.countAccessoriesLength(this.exteriorAccessories)})`,
        },
        {
          id: Tabs.INTERIOR,
          label: `${await this.globalService.getTranslation(Tabs.INTERIOR)} (${this.countAccessoriesLength(this.interiorAccessories)})`,
        },
      ];
      this.segmentSelected = Tabs.EXTERIOR;
      this.accessoriesSegmentChanged(this.segmentSelected);      
    } else {
      this.segments = [
        { id: Tabs.KITS, label: `${ await this.globalService.getTranslation(Tabs.KITS)} (${this.kits.length})` },
        {
          id: Tabs.EXTERIOR,
          label: `${ await this.globalService.getTranslation(Tabs.EXTERIOR)} (${this.countAccessoriesLength(this.exteriorAccessories)})`,
        },
        {
          id: Tabs.INTERIOR,
          label: `${ await this.globalService.getTranslation(Tabs.INTERIOR)} (${this.countAccessoriesLength(this.interiorAccessories)})`,
        },
      ];
      this.segmentSelected = Tabs.KITS;
    }
  }
  accessoriesSegmentChanged(segment) {
    // this.segmentSelected = segment.detail.value;
    this.segmentSelected = segment;
    
    if (!this.segmentSelected) return;
    if (this.segmentSelected == Tabs.KITS) {
      this.viewKits();
    } else if (this.segmentSelected == Tabs.EXTERIOR) {
      this.globalService.viewInterior ? this.viewExterior() : null;
    } else {
      !this.globalService.viewInterior ? this.viewInterior() : null;
    }
    this.events.publish('events', { resetToDefault: null });
  }

  viewKits() {
    return null;
  }
  viewExterior() {
    this.globalService.viewInterior = false;

    if (window.ONE3D && window.ONE3D.onAcc) {
      window.ONE3D.exitAccessoriesView('Exterior');
    } else this.events.publish('events', { view: { id: 'EXT' } });
  }
  viewInterior() {
    this.globalService.viewInterior = true;

    if (window.ONE3D && window.ONE3D.onAcc) {
      window.ONE3D.exitAccessoriesView('Interior');
    } else this.events.publish('events', { view: { id: 'INT' } });
  }
  closeAccessories() {
    // this.modalController.dismiss();
    this.close.emit();
  }
}
