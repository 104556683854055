import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccessoriesService } from 'src/app/services/Accessories/accessory.service';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'accessory-listing',
  templateUrl: './accessory-listing.component.html',
  styleUrls: ['./accessory-listing.component.scss'],
})
export class AccessoryListingComponent implements OnInit {
  @Input() accessoriesList: any;
  @Input() from: string;
  constructor(
    public accessoriesService: AccessoriesService,
    public globalService: GlobalService,
    private events: EventService,
    private apiService: ApiService
  ) {}

  ngOnInit() {}
  /**
   *  Checks for overlapping accessories, if not overlapping then successfully add accessory. Publish event to one3D component to load 3D
   * @param accessory accessory to be added.
   * @param accessoryAddedInPack will have data of pack in which current accessory is already added.
   */
  addAccessory(accessory, accessoryAddedInPack) {
    let acc = this.globalService.addedAccessories.filter((el) => {
      return el.accessories_id == accessory.accessories_id;
    });
    if (acc.length > 0) return;
    if (accessoryAddedInPack.length > 0 && this.from != 'addons') {
      this.accessoriesService.handleOverlappingAccessories(
        accessory,
        accessoryAddedInPack[0]
      );
    } else {
      this.accessoriesService.addAccessory(accessory, this.from);
    }
  }
  /**
   *  Removes added accessory. Publish event to one3D component to remove accessory from 3D
   * @param accessory accessory to be removed
   */
  removeAccessory(accessory) {
    this.accessoriesService.removeAccessory(accessory);
  }

  viewDetails(accessory) {
    this.accessoriesList.map((el) => {
      el.subgroupAccessories.map((subAcc) => {
        if (accessory.accessories_id != subAcc.accessories_id) {
          subAcc.viewDetails = false;
        }
      });
    });
    accessory.viewDetails = !accessory.viewDetails;
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'Accessories description'
    );
  }
}
