import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  isLoading = false;

  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController
  ) { }
  async toastWithButtons(message, color) {
    const toast = await this.toastController.create({
      animated: true,
      position: 'top',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          handler: () => {
            return null;
          },
        },
      ],
      color: color,
      cssClass: 'toast',
      duration: 2000,
      keyboardClose: true,
      message: message,
    });
    toast.present();
  }
  async showLoader(msg = 'Please wait...') {
    this.isLoading = true;
    return await this.loadingController
      .create({
        // mode: 'ios',
        spinner: null,
        // message: 'msg',
        message: '<ion-img src="assets/img/one3d-jeep-loader-new.gif"></ion-img>',
        cssClass: 'one3d-jeep-loader'
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss().then(() => { });
          }
        });
      });
  }

  async hideLoader() {
    this.isLoading = false;
    this.loadingController
      .getTop()
      .then((v) => (v ? this.doStopLoader() : null));
  }
  doStopLoader() {
    this.loadingController.dismiss();
  }
}
