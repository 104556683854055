import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() contentData: any;
  clickSendOTP: boolean = false;
  showRegisterForm: boolean = false;
  showServerError: string = null;
  otpTimeout: number = 30;
  timeLeft: number = 30;
  enableResendOtpButton = false;
  mobileRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  nameRegex = /^[a-zA-Z]+$/;
  otpRegex = /^\d{4}$/;
  backupMobile: any;
  timerId: any;

  mobileNumberForm: UntypedFormGroup = this.formBuilder.group({
    mobile: [
      null,
      {
        validators: [Validators.pattern(this.mobileRegex), Validators.required],
        updateOn: 'blur',
      },
    ],
  });

  verifyOtpForm: UntypedFormGroup = this.formBuilder.group({
    mobile: [
      null,
      {
        validators: [Validators.pattern(this.mobileRegex), Validators.required],
        updateOn: 'blur',
      },
    ],
    otp: [
      null,
      {
        validators: [Validators.pattern(this.otpRegex), Validators.required],
        updateOn: 'change',
      },
    ],
  });

  loginForm: UntypedFormGroup = this.formBuilder.group({
    salutation: [
      'Mr',
      {
        validators: [Validators.required],
      },
    ],
    firstName: [
      null,
      {
        validators: [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(this.nameRegex),
          Validators.required,
        ],
        updateOn: 'blur',
      },
    ],
    lastName: [
      null,
      {
        validators: [
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(this.nameRegex),
          Validators.required,
        ],
        updateOn: 'blur',
      },
    ],
    email: [
      null,
      {
        validators: [
          Validators.maxLength(100),
          Validators.pattern(this.emailRegex),
          Validators.required,
        ],
        updateOn: 'change',
      },
    ],
  });
  public submitMobileAttempt: boolean = false;
  public submitOtpFormAttempt: boolean = false;
  public submitLoginFormAttempt: boolean = false;

  constructor(
    private modalController: ModalController,
    public formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private widgetsService: WidgetsService,
    private cachingService: CachingService,
    public globalService: GlobalService,
    private events: EventService
  ) {
    this.loginForm.get('email').valueChanges.subscribe((e) => {
      this.showServerError = null;
    });

    this.verifyOtpForm.get('otp').valueChanges.subscribe((e) => {
      this.showServerError = null;
    });
  }

  ngOnInit() {}

  redirectToMobileForm() {
    this.clickSendOTP = false;
  }

  otpTimer() {
    this.timerId = setInterval(() => {
      if (this.timeLeft == 0) {
        clearTimeout(this.timerId);
        this.timeLeft = this.otpTimeout;
        this.enableResendOtpButton = true;
      } else {
        this.timeLeft = this.timeLeft - 1;
      }
    }, 1000);
  }

  /**
   * To send OTP on number entered by user
   */
  sendOTP(resend?) {
    this.submitMobileAttempt = true;
    this.enableResendOtpButton = false;
    this.showServerError = null;

    // if newly entered mobile number & prev mobile number is same don't make sendOtp api call again
    // else clearTimeout and again start new timer
    if (
      this.backupMobile == this.mobileNumberForm.get('mobile').value &&
      !resend
    ) {
      this.clickSendOTP = true;
      // if after otpTimeout user tries to edit but enter same number enableResendOtpButton
      if (this.timeLeft == this.otpTimeout) this.enableResendOtpButton = true;
      return;
    } else {
      clearTimeout(this.timerId);
      this.timeLeft = this.otpTimeout;
    }

    if (this.mobileNumberForm.valid) {
      this.backupMobile = this.mobileNumberForm.get('mobile').value;
      this.otpTimer();
      let mobileForm = this.mobileNumberForm.value;

      let requestBody = {
        mobile_number: mobileForm.mobile,
      };
      this.apiService.post('customer/send-otp', requestBody).then(
        async (res: any) => {
          this.widgetsService.hideLoader();
          if (res.msg == 'success') {
            resend
              ? this.apiService.logGAEvent(
                  'uaevent',
                  'Content',
                  'Click',
                  'Resend OTP'
                )
              : this.apiService.logGAEvent(
                  'uaevent',
                  'Content',
                  'Click',
                  'Send OTP'
                );
            this.clickSendOTP = true;
            this.verifyOtpForm.get('mobile').setValue(mobileForm.mobile);
          } else {
            this.widgetsService.toastWithButtons(
              'Something went wrong!',
              'danger'
            );
          }
        },
        (error) => {
          this.widgetsService.hideLoader();
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
        }
      );
    }
  }

  /**
   * To verify OTP entered by user, if already registered user directly login else show loginForm to get further details
   */
  verifyOTP() {
    this.submitOtpFormAttempt = true;
    let button = '';
    if (this.contentData.action == 'proceedToBook') {
      button = 'PROCEED TO BOOK';
    } else if (this.contentData.action == 'save') {
      button = 'SAVE';
    } else {
      button = 'LOGIN';
    }
    console.log('User Action From ' + button + ' button');

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Action',
      'User Action From ' + button + ' button'
    );
    if (this.verifyOtpForm.valid) {
      let verifyOtpForm = this.verifyOtpForm.value;
      let requestBody = {
        mobile_number: verifyOtpForm.mobile,
        otp_code: verifyOtpForm.otp,
      };

      this.apiService.post('customer/verify-otp', requestBody).then(
        async (res: any) => {
          this.widgetsService.hideLoader();
          if (res.msg == 'success') {
            this.apiService.logGAEvent(
              'uaevent',
              'Content',
              'Click::Action',
              'Verify OTP'
            );
            this.showServerError = null;
            let visitorInfo = await this.cachingService.getDataFromLocal(
              'visitor_id'
            );
            let userDetails = res.response; //response is object
            userDetails.customer_id = String(userDetails.customer_id); //to convert customer_id value to type string as backend accept it in string in payload
            userDetails.visitor_id = visitorInfo;
            if (userDetails.first_name && userDetails.email) {
              this.loginUser(userDetails);
            } else {
              //set userDetails in local storage
              await this.cachingService.setDataInLocal(
                'userDetails',
                userDetails
              );
              this.showRegisterForm = true;
              // change description for new user in registerForm
              this.contentData.msgContent =
                'It seems like you are a new here. Please help us with your name and email address.';
            }
          } else if (res.msg == 'fail' && typeof res.message == 'string') {
            this.showServerError = res.message;
          } else {
            this.widgetsService.toastWithButtons(
              'Something went wrong!',
              'danger'
            );
          }
        },
        (error) => {
          this.widgetsService.hideLoader();
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
        }
      );
    }
  }

  /**
   * To gather all new user details from loginForm & local storage at one place and submit to login api
   */
  async registerNewUser() {
    this.submitLoginFormAttempt = true;

    //get details from local storage {customer_id, mobile_number} & login form
    let userDetails = await this.cachingService.getDataFromLocal('userDetails');
    let visitorInfo = await this.cachingService.getDataFromLocal('visitor_id');
    if (this.loginForm.valid) {
      let loginDetails = this.loginForm.value;
      let details = {
        customer_id: userDetails.customer_id,
        salutation: loginDetails.salutation,
        first_name: loginDetails.firstName,
        last_name: loginDetails.lastName,
        mobile_number: userDetails.mobile_number,
        email: loginDetails.email,
        visitor_id: visitorInfo,
      };
      this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'Login submit');

      this.apiService.post('customer/login', details).then(
        async (res: any) => {
          this.widgetsService.hideLoader();
          if (res.msg == 'success') {
            this.showServerError = null;
            this.closeModal('success');
            this.widgetsService.toastWithButtons(
              'You have been successfully logged in!',
              'success'
            );

            let userDetails = res.response[0]; //since response is array of object for login api
            this.globalService.customerInfo = userDetails;

            await this.cachingService.setDataInLocal(
              'userDetails',
              userDetails
            ); //update userDetails in local storage
          } else if (res.msg == 'fail' && typeof res.response == 'string') {
            this.showServerError = res.response;
          } else {
            this.widgetsService.toastWithButtons(
              'Something went wrong!',
              'danger'
            );
          }
        },
        (error) => {
          this.widgetsService.hideLoader();
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
        }
      );
    }
  }

  /**
   * user Login api call
   * @param {*} requestBody object in which all key, value required for login api is passed
   */
  loginUser(requestBody) {
    this.apiService.post('customer/login', requestBody).then(
      async (res: any) => {
        this.widgetsService.hideLoader();
        if (res.msg == 'success') {
          this.closeModal('fail');
          let userDetails = res.response[0]; //since response is array of object for login api
          this.globalService.customerInfo = userDetails;

          await this.cachingService.setDataInLocal('userDetails', userDetails); //update userDetails in local storage

          if (this.contentData.from == 'header') {
            this.loginSuccessfulPopup();
          } else if (this.contentData.from == 'cta') {
            this.globalService.saveConfiguration(this.contentData.action);
          }
        } else {
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons('Something went wrong!', 'danger');
      }
    );
  }

  closeModal(event) {
    this.modalController.dismiss(event);
  }

  async loginSuccessfulPopup() {
    this.widgetsService.toastWithButtons(
      'You have been successfully logged in!',
      'success'
    );
    // const modal = await this.modalController.create({
    //   component: ConfirmationModalComponent,
    //   componentProps: {
    //     msg: {
    //       header: 'LOGGED IN!',
    //       content: 'You have logged in Successfully.',
    //       cancelText: 'VIEW PROFILE',
    //       confirmText: 'CONTINUE',
    //     },
    //   },
    //   backdropDismiss: false,
    //   cssClass: 'confirmation-modal',
    // });
    // await modal.present();
    // const { data } = await modal.onDidDismiss();
    // !data ? this.viewProfile() : null;
  }

  async viewProfile() {
    // this.events.publish('events', { viewProfile: true });
    const modal = await this.modalController.create({
      component: ProfileComponent,
      backdropDismiss: false,
      animated: false,
      cssClass: 'one3d-sidePanelModal-summary',
    });

    await modal.present();
  }
}
