import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { encode, decode } from 'ee-crypto-encode-decode';
@Injectable({
  providedIn: 'root',
})
export class CachingService {
  encryptData = true;
  constructor() {}

  async setDataInLocal(key: string, data: any) {
    let dataToStore = this.encryptData ? encode(data) : JSON.stringify(data);
    await Storage.set({ key: key, value: dataToStore });
  }
  async setDataInSession(key: string, data: any) {
    let dataToStore = this.encryptData ? encode(data) : JSON.stringify(data);
    sessionStorage.setItem(key, dataToStore);
  }

  async getDataFromLocal(key: string) {
    const data = await Storage.get({ key: key });
    if (data.value != null && data.value != '') {
      let decryptedData = this.encryptData
        ? decode(JSON.parse(data.value))
        : JSON.parse(data.value);
      return decryptedData;
    } else return null;
  }

  async getDataFromSession(key: string) {
    const data = await sessionStorage.getItem(key);
    if (data != null && data != '') {
      let decryptedData = this.encryptData
        ? decode(JSON.parse(data))
        : JSON.parse(data);
      return decryptedData;
    } else return null;
  }

  async removeDataFromLocal(key) {
    console.log('removeDataFromLocal', key);
    await Storage.remove({ key: key });
  }
  async removeDataFromSession(key) {
    console.log('removeDataFromSession', key);
    sessionStorage.removeItem(key);
  }
}
