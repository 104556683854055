import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/Events/events.service';
declare var lottie;
@Component({
  selector: 'app-loader-modal',
  templateUrl: './loader-modal.component.html',
  styleUrls: ['./loader-modal.component.scss'],
})
export class LoaderModalComponent implements OnInit {
  loaderPercent: number;
  progressBars: any[] = [
    'one3d-loader-bar-1',
    'one3d-loader-bar-2',
    'one3d-loader-bar-3',
    'one3d-loader-bar-4',
    'one3d-loader-bar-5',
    'one3d-loader-bar-6',
    'one3d-loader-bar-7',
  ];
  constructor(private events: EventService) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }

  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'carLoadPercent':
        this.loaderPercent = data.carLoadPercent;
        this.fillBars(data.carLoadPercent);
        break;
      case 'selectedVariant':
        break;
      default:
        break;
    }
  }
  /**
   * fill the individual bars of the main loader
   * @param data Loading percentage received from 3D loader
   */
  fillBars(data) {
    let loadedPercent = data;
    let barIndex = Math.floor(loadedPercent / 14);
    if (this.progressBars[barIndex]) {
      let selectedBar = document.querySelector(
        '#' + this.progressBars[barIndex]
      );
      let barReminder = loadedPercent % 14;
      selectedBar
        ? (selectedBar['style'].height = barReminder * 7 + '%')
        : null;
      if (document.querySelector('#' + this.progressBars[barIndex - 1])) {
        document.querySelector('#' + this.progressBars[barIndex - 1])[
          'style'
        ].height = '100%';
      }
    } else {
      if (document.querySelector('.one3d-loader-left')?.classList) {
        document
          .querySelector('.one3d-loader-left')
          .classList.remove('one3d-loader-blink');
        document
          .querySelector('.one3d-loader-right')
          .classList.remove('one3d-loader-blink');
        let selectedBar = document.querySelector(
          '#' + this.progressBars[this.progressBars.length - 1]
        );
        selectedBar['style'].height = '100%';
      }
    }
  }
}
