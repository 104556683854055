import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';
@Component({
  selector: 'colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss'],
})
export class ColorsComponent implements OnInit {
  colors: any;
  colorSelected: any;
  constructor(
    private events: EventService,
    private widgetService: WidgetsService,
    private globalService: GlobalService,
    private cachingService: CachingService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }

  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'firstLoadComplete':
        this.getConfigurationColors(data.firstLoadComplete);
        break;
      case 'selectedVariant':
        this.getColors(data.selectedVariant);
        break;
      case 'loadOldConfiguration':
        this.getConfigurationColors(data.loadOldConfiguration);
        break;
      case 'newConfiguration':
        this.getConfigurationColors(data.newConfiguration);
      default:
        break;
    }
  }

  async getConfigurationColors(selectedVariant) {
    this.colors = selectedVariant.color;
    let oldConfiguration = await this.cachingService.getDataFromSession(
      'oldConfiguration'
    );
    let oldConfigurationFromSession =
      await this.cachingService.getDataFromSession(
        'oldConfigurationFromSession'
      );
    if (oldConfiguration || oldConfigurationFromSession) {
      this.colorSelected = this.colors.filter((el) => {
        return el.variant_color_id == this.globalService.variant_color_id;
      })[0];
    } else {
      if (
        this.globalService.vehicleCode &&
        this.globalService.zipCode &&
        this.globalService.cpos
      ) {
        if (this.globalService.optionalCodes) {
          this.colorSelected = this.colors.filter((variant) => {
            return (
              variant.exterior_color_code ==
                this.globalService.optionalCodes.exteriorCode &&
              variant.interior_color_code ==
                this.globalService.optionalCodes.interiorCode &&
              variant.infotainment_code ==
                this.globalService.optionalCodes.infotainmentCode
            );
          })[0];
        } else {
          this.colorSelected = this.colors.filter((el) => {
            return el.is_default == 'yes';
          })[0];
        }
      } else {
        this.colorSelected = this.colors.filter((el) => {
          return el.is_default == 'yes';
        })[0];
      }
    }
    if (!this.colorSelected) {
      this.colorSelected = this.colors.filter((el) => {
        return el.is_default == 'yes';
      })[0];
    }
    this.globalService.colorSelected = this.colorSelected;
    this.events.publish('events', { selectedColor: this.colorSelected });
    this.globalService.setConfigData();
    let oldConfig = await this.cachingService.getDataFromSession(
      'oldConfiguration'
    );
    if (window.location.pathname.includes('visualizer')) {
      this.globalService.configuration('create');
    } else {
      this.globalService.configuration(
        oldConfig || oldConfigurationFromSession ? 'update' : 'create'
      );
    }
  }

  async getColors(selectedVariant) {
    if (selectedVariant) {
      this.colors = selectedVariant.color;
      this.getDefaultColor();
    } else {
      this.widgetService.toastWithButtons('Something went wrong', 'danger');
    }
  }
  getDefaultColor() {
    this.colorSelected = this.colors.filter((el) => {
      return el.is_default == 'yes';
    })[0];
    this.globalService.colorSelected = this.colorSelected;

    this.events.publish('events', { selectedColor: this.colorSelected });
    this.globalService.setConfigData();
    this.globalService.configuration('update');
  }

  returnColor(hexCode: string) {
    if (hexCode) {
      return hexCode.split(',')[0];
    }
    return '';
  }
  returnDualToneColor(hexCode: string) {
    if (hexCode) {
      if (hexCode.split(',').length > 1) {
        return hexCode.split(',')[1];
      } else {
        return hexCode.split(',')[0];
      }
    }
  }

  selectColor(color) {
    this.colorSelected = color;
    this.events.publish('events', { selectedColor: this.colorSelected });
    this.globalService.colorSelected = this.colorSelected;
    this.globalService.setConfigData();
    this.globalService.configuration('update');
    this.apiService.visualizerAnalytics('color_options');
  }
}
