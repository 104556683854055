import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/Events/events.service';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'interior-exterior-buttons',
  templateUrl: './interior-exterior-buttons.component.html',
  styleUrls: ['./interior-exterior-buttons.component.scss'],
})
export class InteriorExteriorButtonsComponent implements OnInit {
  microInteractions: any;
  showIntExtButtons = true;
  seatingView = 'frontSeatView';
  selectedRowValue: any;
  fromCallBack = false;
  showResetButton = false;

  constructor(
    private events: EventService,
    public globalService: GlobalService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }

  async subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'resetToDefault':
        this.showIntExtButtons = true;
        this.showResetButton = false;
        break;
      case 'accessoryLoading':
        this.showIntExtButtons = false;
        this.showResetButton = false;
        break;
      case 'accessoryLoadingComplete':
        this.showIntExtButtons = false;
        this.showResetButton = true;
        break;
      case 'view':
        switch (data.view.id) {
          case 'INT':
            this.globalService.viewInterior = true;
            this.seatingView = 'frontSeatView';
            this.selectedRowValue = await this.globalService.getTranslation("1ST_ROW");
            this.fromCallBack = false;
            break;
          case 'EXT':
            this.globalService.viewInterior = false;
            break;
          case 'INT_COMPLETE':
            this.globalService.viewInterior = true;
            break;
          case 'EXT_COMPLETE':
            this.globalService.viewInterior = false;
            break;
          default:
            break;
        }
        break;
      case 'frontSeatViewComplete':
        this.seatingView = 'frontSeatView';
        this.selectedRowValue = await this.globalService.getTranslation("1ST_ROW");
        this.fromCallBack = true;
        break;
      case 'backSeatViewComplete':
        this.seatingView = 'backSeatView';
        this.selectedRowValue = await this.globalService.getTranslation("2ND_ROW");
        this.fromCallBack = true;
        break;
      case 'lastSeatViewComplete':
        this.seatingView = 'lastSeatView';
        this.selectedRowValue = await this.globalService.getTranslation("3RD_ROW");
        this.fromCallBack = true;
        break;
      default:
        break;
    }
  }

  viewExterior() {
    if (this.globalService.viewInterior) {
      this.globalService.viewInterior = false;
      this.events.publish('events', { view: { id: 'EXT' } });
      this.apiService.logGAEvent('uaevent', 'Content', 'Click::Toolbar', 'ext');
    }
  }
  viewInterior() {
    if (!this.globalService.viewInterior) {
      this.globalService.viewInterior = true;
      this.events.publish('events', { view: { id: 'INT' } });
      this.apiService.logGAEvent('uaevent', 'Content', 'Click::Toolbar', 'int');
    }
  }
  viewSeats(event) {
    this.events.publish('events', {
      view: { id: 'SEAT', value: event.target.value },
    });
  }
  resetToDefaultPosition() {
    this.events.publish('events', { resetToDefault: null });
  }
}
