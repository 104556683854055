import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/Events/events.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { KeyFeaturesModalComponent } from '../key-features-modal/key-features-modal.component';
import { CachingService } from './../../services/cache/caching.service';
import { GlobalService } from './../../services/global/global.service';

@Component({
  selector: 'variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.scss'],
})
export class VariantsComponent implements OnInit {
  variantsFilters: any;
  variants: any;
  selectedVariant;
  variantsForUi: any;
  driveType: any;
  transmissionType: any;
  modalOpen = false;
  constructor(
    public globalService: GlobalService,
    private cachingService: CachingService,
    private events: EventService,
    private apiService: ApiService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }
  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'defaultVariant':
        this.selectedVariant = data.defaultVariant;
        this.getFilters(this.selectedVariant);
        this.globalService.selectedVariant = this.selectedVariant;
        this.globalService.setConfigData();
        break;
      case 'newConfiguration':
        this.selectedVariant = data.newConfiguration;
        this.getFilters(this.selectedVariant);
        this.globalService.selectedVariant = this.selectedVariant;
        this.globalService.setConfigData();
        break;
      case 'loadOldConfiguration':
        this.selectedVariant = data.loadOldConfiguration;
        this.getFilters(this.selectedVariant);
        this.globalService.selectedVariant = this.selectedVariant;
        this.globalService.setConfigData();
        break;
      case 'selectedVariant':
        this.selectedVariant = data.selectedVariant;
        this.globalService.selectedVariant = this.selectedVariant;
        this.globalService.setConfigData();
        break;
      default:
        break;
    }
  }
  async getFilters(selectedVariant) {
    let variant_details = await this.cachingService.getDataFromSession(
      'variants'
    );
    this.variants = variant_details.variants;
    this.manageFilters(selectedVariant, variant_details.filters);
  }
  manageFilters(variant, filters) {
    for (let index = 0; index < filters.length; index++) {
      if (filters[index].filterName == 'Drive') {
        filters[index].filterValue = variant.drive;
        this.driveType = variant.drive;
      } else {
        filters[index].filterValue = variant.transmission_code;
        this.transmissionType = variant.transmission_code;
      }
    }
    this.variantsFilters = filters;
    this.variantsForUi = this.variants.filter((element) => {
      return (
        element.drive == variant.drive &&
        element.transmission_code == variant.transmission_code
      );
    });
    this.variantsForUi.sort((a: any, b: any) =>
      a.sorting > b.sorting ? 1 : b.sorting > a.sorting ? -1 : 0
    );
  }
  filterVariants(key, event) {
    let filterValue;
    if (key == 'Drive') {
      event.detail.checked ? (filterValue = '4x2') : (filterValue = '4x4');
      this.sortByDriveType(filterValue);
      this.apiService.visualizerAnalytics('drive_filter');
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click::Toggle',
        filterValue
      );
    } else if (key == 'Transmission') {
      event.detail.checked ? (filterValue = '6MT') : (filterValue = '9AT');
      this.sortByTransmissionType(filterValue);
      this.apiService.visualizerAnalytics('transmission_filter');
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click::Toggle',
        filterValue
      );
    }
  }

  sortByDriveType(data) {
    let driveTypeState = this.driveType;
    let transmissionTypeState = this.transmissionType;
    this.driveType = data;
    this.variantsForUi = this.variants.filter((el) => {
      return el.drive == data && el.transmission_code == this.transmissionType;
    });
    this.sortOrderForVariants(driveTypeState, transmissionTypeState);
  }
  sortByTransmissionType(data) {
    let driveTypeState = this.driveType;
    let transmissionTypeState = this.transmissionType;
    this.transmissionType = data;
    if (this.transmissionType == '6MT') {
      this.driveType = '4x2';
    }
    this.variantsForUi = this.variants.filter((el) => {
      return el.transmission_code == data && el.drive == this.driveType;
    });
    this.sortOrderForVariants(driveTypeState, transmissionTypeState);
  }

  sortOrderForVariants(driveType, transmissionType) {
    this.variantsForUi.sort((a: any, b: any) =>
      a.sorting > b.sorting ? 1 : b.sorting > a.sorting ? -1 : 0
    );
    let selectedVariant = this.variantsForUi[0];
    if (selectedVariant) {
      this.selectVariantCheck(selectedVariant, driveType, transmissionType);
    }
  }
  async selectVariantCheck(selectedVariant, driveType?, transmissionType?) {
    if (
      this.selectedVariant.variant_id == selectedVariant.variant_id ||
      this.modalOpen
    )
      return;
    if (
      this.globalService.addedAccessories.length > 0 ||
      this.globalService.addedAccessoriesPacks.length > 0
    ) {
      this.modalOpen = true;
      const modal = await this.modalController.create({
        component: ConfirmationModalComponent,
        componentProps: {
          msg: {
            icon: 'assets/icons/icon-switching-variant.svg',
            header: 'SWITCHING VARIANT',
            content:
              'You are about to switch variant. This will create a new configuration.',
            cancelText: 'CANCEL',
            confirmText: 'CONFIRM',
          },
        },
        backdropDismiss: false,
        cssClass: 'confirmation-modal',
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.globalService.addedAccessories = [];
        this.globalService.addedAccessoriesPacks = [];
        this.selectVariant(selectedVariant);
      } else {
        this.driveType = driveType;
        this.transmissionType = transmissionType;
      }
      setTimeout(() => {
        this.modalOpen = false;
      }, 500);
    } else {
      this.selectVariant(selectedVariant);
    }
  }
  selectVariant(selectedVariant) {
    this.globalService.getVariantDetails(selectedVariant, 'showLoader');
    this.selectedVariant = selectedVariant;
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Variant',
      selectedVariant.version_name
    );
    this.events.publish('events', { selectedVariant: selectedVariant });
    this.apiService.visualizerAnalytics('variant_switch');
  }

  async showKeyFeatures(variant) {
    this.apiService.visualizerAnalytics('variant_info');
    const modal = await this.modalController.create({
      component: KeyFeaturesModalComponent,
      componentProps: {
        variants: this.variantsForUi,
        infoForVariant: variant,
        selectedVariant: this.selectedVariant,
      },
      backdropDismiss: false,
      animated: false,
      cssClass: 'one3d-keyFeatures-modal',
    });

    await modal.present();
  }
}
