import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'app-car-exchange',
  templateUrl: './car-exchange.component.html',
  styleUrls: ['./car-exchange.component.scss'],
})
export class CarExchangeComponent implements OnInit {
  loadIframe = false;
  iframeUrl: string;
  pin;
  carExchange;
  showCalculation = false;
  constructor(
    private modalController: ModalController,
    private cachingService: CachingService,
    public globalService: GlobalService,
    private apiService: ApiService
  ) {
    window.addEventListener('message', (e) => {
      const data = JSON.parse(e.data);
      console.log('Exchange', data);
      if (
        data.iframeComponent == 'Car Exchange' &&
        data.response == 'success'
      ) {
        this.getCarExchangeFromAPi();
      } else {
        this.showCalculation = true;
        this.modalController.dismiss('fail');
      }
    });
  }
  async getCarExchangeFromAPi() {
    const pin_no = await this.cachingService.getDataFromSession('pin_no');
    const requestBody = {
      pin_no: pin_no,
    };
    this.apiService
      .post('configuration/get-exchange-values', requestBody)
      .then((res: any) => {
        if (res.msg == 'success') {
          // this.cachingService.setDataInSession(
          //   'exchange_values',
          //   res.response[0]
          // );
          this.carExchange = res.response[0];
          this.showCalculation = true;
          // this.getCarExchange();
          this.apiService.logGAEvent(
            'uaevent',
            'Content',
            'Click',
            'get car exchange values'
          );
        } else {
          console.error('Exchange Values Error ');
        }
      })
      .catch((err) => {
        console.error('Exchange Values: ', err);
      });
  }

  async getCarExchange() {
    let carExchange = await this.cachingService.getDataFromSession(
      'exchange_values'
    );
    if (carExchange?.MinimumValue) {
      this.carExchange = carExchange;
    } else {
      this.carExchange = null;
    }
  }

  ngOnInit() {
    this.getPin();
  }
  async getPin() {
    this.pin = await this.cachingService.getDataFromSession('pin_no');
    this.iframeUrl = `https://jeepselectedforyou.in/used-car-valuation/?token=WS1ngjLGRSab13D8jTdU6dTrh&pid=${this.pin}`;
    console.log(this.iframeUrl);

    this.loadIframe = true;
  }
  skipExchange() {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'skip car exchange'
    );
    this.modalController.dismiss();
  }
  addExchange() {
    this.cachingService.setDataInSession('exchange_values', this.carExchange);
    this.modalController.dismiss('success');
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'add car exchange to summary'
    );
  }
  closeCarExchange() {
    this.modalController.dismiss();
  }
  editValues() {
    this.showCalculation = false;
    this.loadIframe = true;
  }
}
