import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() msg: any;
  confirmation: boolean;
  constructor(private modalController: ModalController) {}

  ngOnInit() {}
  cancel() {
    // const message = JSON.stringify({
    //   iframeComponent: 'Car Exchange',
    //   response: 'success',
    // });
    // window.parent.postMessage(message, '*');
    this.confirmation = false;
    this.modalController.dismiss(this.confirmation);
  }
  continue() {
    this.confirmation = true;
    this.modalController.dismiss(this.confirmation);
  }
}
