import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CachingService } from 'src/app/services/cache/caching.service';
import { EventService } from 'src/app/services/Events/events.service';

@Component({
  selector: 'add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss'],
})
export class AddOnsComponent implements OnInit {
  @Input() mobile: any;
  @Output() close =  new EventEmitter;
  addonsAccessories = [];
  constructor(
    private events: EventService,
    private cachingService: CachingService,
    private modalController: ModalController
  ) {}
  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
    if (this.mobile == 'mobile') {
      this.getDataForMobile();
    }
  }

  async getDataForMobile() {
    let variantDetails = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    this.getAccessories(variantDetails);
  }
  subscribeToEvents(data) {
    let key = Object.keys(data)[0];
    switch (key) {
      case 'variantDetails':
        this.getAccessories(data.variantDetails);
        break;
      default:
        break;
    }
  }
  getAccessories(variant) {
    let accessories = variant.accessories;
    for (let index = 0; index < accessories.length; index++) {
      if (accessories[index].name.toLowerCase().includes('add')) {
        this.addonsAccessories = accessories[index].subGroups;
      }
    }
  }
  closeAccessories() {
    // this.modalController.dismiss();
    this.close.emit()
  }
}
