import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-save-share-popup',
  templateUrl: './save-share-popup.component.html',
  styleUrls: ['./save-share-popup.component.scss'],
})
export class SaveSharePopupComponent implements OnInit {
  smsForm: UntypedFormGroup;
  smsFormSubscription: Subscription;
  mailForm: UntypedFormGroup;
  mailFormSubscription: Subscription;
  showSuccessMsg = false;

  downloadURL: string;

  shareOptions = [
    // {
    //   id: 'SMS',
    //   label: 'SMS',
    //   icon: 'assets/img/icons/one3d-sms-icon.svg',
    // },
    // {
    //   id: 'MAIL',
    //   label: 'Mail',
    //   icon: 'assets/img/icons/one3d-mail-icon.svg',
    // },
    {
      id: 'WA',
      label: 'Whatsapp',
      icon: 'assets/img/icons/one3d-whatsapp-icon.svg',
    },
    {
      id: 'DL',
      label: 'Download',
      icon: 'assets/img/icons/one3d-popup-download-icon.svg',
    },
  ];
  selectedShareOption = this.shareOptions[0];
  formErrors = {
    phoneNo: '',
    email: '',
  };
  validationMessages = {
    phoneNo: {
      required: 'Mobile No. is required.',
      pattern: 'Please enter valid mobile no.',
    },
    email: {
      required: 'Email ID is required.',
      pattern: 'Please enter valid email address.',
    },
  };
  showSuccessCopyLink: boolean;
  constructor(
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    public globalService: GlobalService,
    private apiService: ApiService,
    private widgetsService: WidgetsService,
    private cachingService: CachingService
  ) {}

  ngOnInit() {
    this.downloadConfiguration();
  }

  // ngOnDestroy(): void {
  //   if (
  //     this.selectedShareOption.id == 'SMS' ||
  //     this.selectedShareOption.id == 'WA'
  //   ) {
  //     this.smsFormSubscription.unsubscribe();
  //   } else if (this.selectedShareOption.id == 'MAIL') {
  //     this.mailFormSubscription.unsubscribe();
  //   }
  // }

  async selectShareOption(option) {
    this.selectedShareOption = option;
    this.showSuccessMsg = false;
    if (
      this.selectedShareOption.id == 'SMS' ||
      this.selectedShareOption.id == 'WA'
    ) {
      this.mailFormSubscription.unsubscribe();
      this.setSmsForm();
    } else if (this.selectedShareOption.id == 'MAIL') {
      this.smsFormSubscription.unsubscribe();
      this.setMailForm();
    } else if (this.selectedShareOption.id == 'DL') {
      this.downloadConfiguration();
    }
  }
  async downloadConfiguration() {
    let pin_no = await this.cachingService.getDataFromSession('pin_no');
    this.downloadURL = environment.apiUrl + 'api/' + this.globalService.apiVersion + `pdf/download/${pin_no}`
    console.log("generated download url", this.downloadURL);
    var link = document.getElementById('pdf-url-input');
    link.setAttribute('value', this.downloadURL);
    // this.apiService
    //   .download(`pdf/download/${pin_no}`)
    //   .subscribe((data: any) => {
    //     console.log("data", data);
        
        // this.downloadURL = window.URL.createObjectURL(data);
        // link.download = `${pin_no}.pdf`;
        // link.click();
      // });
    // this.apiService.logGAEvent(
    //   'uaevent',
    //   'Content',
    //   'Click::Action',
    //   'Download'
    // );
  }

  // to copy download pdf url on click of copy url btn
  copyURL() {
    navigator.clipboard.writeText(this.downloadURL);
    this.showSuccessCopyLink = true;
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Save',
      'Copy URL'
    );
    this.globalService.copy_url_clicked = 'Yes'; //to update if this action is clicked or not
    this.globalService.configuration('update');
    // setTimeout(() => {
    //   this.showSuccessCopyLink = false
    // }, 10000)
  }

  // to download summary pdf on click on btn
  downloadPdf() {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Save',
      'PDF Download'
    );
    this.globalService.pdf_download_clicked = 'Yes'; //to update if this action is clicked or not
    this.widgetsService.showLoader();
    this.globalService.configuration('update').then(() => {
      this.widgetsService.hideLoader();
      window.open(this.downloadURL, '_parent'); //open download url on success of api call as api is failing in ios devices
    }).catch(() => {
      this.widgetsService.hideLoader();
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  setSmsForm() {
    this.smsForm = this.formBuilder.group({
      phoneNo: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(this.globalService.mobileNumberRegex),
          ],
        },
      ],
    });
    this.smsFormSubscription = this.smsForm.valueChanges.subscribe(() =>
      this.onValueChanged('smsForm')
    );
    this.onValueChanged('smsForm');
  }
  setMailForm() {
    this.mailForm = this.formBuilder.group({
      email: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(this.globalService.emailRegex),
          ],
        },
      ],
    });
    this.mailFormSubscription = this.mailForm.valueChanges.subscribe(() =>
      this.onValueChanged('mailForm')
    );
    this.onValueChanged('mailForm');
  }
  onValueChanged(formName) {
    if (!this[formName]) {
      return;
    }
    const form = this[formName];
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key];
        }
      }
    }
  }
  async shareConfiguration() {
    let pin_no = await this.cachingService.getDataFromSession('pin_no');

    let requestBody = {
      pin_no: pin_no,
      mobile_number: this.smsForm.get('phoneNo').value,
      send_notification: 'yes',
    };
    this.apiService.post('configuration/share', requestBody).then(
      async (data: any) => {
        this.widgetsService.hideLoader();
        if (data.msg == 'success') {
          this.showSuccessMsg = true;
          // this.modalController.dismiss();
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(
          this.globalService.apiErrorMsg,
          'danger'
        );
        console.error('Error : ', error);
      }
    );
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Action',
      'Whatsapp Submit'
    );
  }
}
