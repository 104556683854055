import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { BuildnameModalComponent } from 'src/app/components/buildname-modal/buildname-modal.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { PrivacyPolicyComponent } from 'src/app/components/privacy-policy/privacy-policy.component';
import { SaveSharePopupComponent } from 'src/app/components/save-share-popup/save-share-popup.component';

import { ApiService } from '../API/api.service';
import { CachingService } from '../cache/caching.service';
import { EventService } from '../Events/events.service';
import { WidgetsService } from '../ui/widgets.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  apiErrorMsg = 'Something went wrong!';
  colorSelected: any;
  selectedVariant: any;
  addedAccessories = [];
  addedAccessoriesPacks = [];
  activeTab: string;
  vehicleCode: string;
  zipCode: string;
  cpos: any;
  viewInterior = false;
  optionalCodes: any;
  mobileNumberRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  nameRegex = /^[ a-zA-Z\-\']+$/;
  variant_color_id: any;
  buildName: any;
  customerInfo: any;
  imageAccessoryOpen = false;

  // parameter in order to update api on click of Save btn/copy url btn/ download pdf btn in jeep-japan
  // initial values to set the click status of below cta
  savedStatus = 'unsaved';
  pdf_download_clicked = 'No';
  copy_url_clicked = 'No';

  apiVersion = 'v2/'; //to set current apiVersion

  constructor(
    private cachingService: CachingService,
    private apiService: ApiService,
    private widgetsService: WidgetsService,
    private platform: Platform,
    private modalController: ModalController,
    private events: EventService,
    private translate: TranslateService
  ) {}

  // For Japanese currency format passed "ja-JP"
  currencyFormatterIN(price) {
    return price ? Number(price).toLocaleString('ja-JP') : 'X,XXX,XXX';
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  async setConfigData() {
    return new Promise((resolve, reject) => {
      let config = {
        colorSelected: this.colorSelected,
        selectedVariant: this.selectedVariant,
        addedAccessories: this.addedAccessories,
        addedAccessoriesPacks: this.addedAccessoriesPacks,
      };
      this.cachingService.setDataInSession('config', config).then((res) => {
        resolve(true);
      });
    });
  }
  async getVariantDetails(selectedVariant, showLoader?) {
    if (showLoader) {
      this.widgetsService.showLoader();
    }
    let requestBody = {
      variant_id: selectedVariant.variant_id,
      lang: 'ja-JP',
      source: 'web',
    };
    this.apiService.post('variants/details', requestBody).then(
      async (res: any) => {
        this.widgetsService.hideLoader();
        if (res.msg == 'success') {
          this.selectedVariant = selectedVariant;
          let variantDetails = res.response.variants[0];
          await this.cachingService.setDataInSession(
            'variant_details',
            variantDetails
          );
          await this.cachingService.setDataInSession(
            'model_details',
            res.response
          );
          this.events.publish('events', {
            variantDetails: variantDetails,
          });
          this.events.publish('events', {
            variantFeatures: variantDetails,
          });
        } else {
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons('Something went wrong!', 'danger');
      }
    );
  }

  /**
   * to call configurator api according to operation passed
   * @param crudOperation create/update operation
   * @returns true/false according to success/failure
   */
  configuration(crudOperation: string) {
    if (window.location.pathname.includes('visualizer')) return;
    return new Promise(async (resolve, reject) => {
      let variantDetails = await this.cachingService.getDataFromSession(
        'variant_details'
      );
      let variants = await this.cachingService.getDataFromSession('variants');
      let pin_no = await this.cachingService.getDataFromSession('pin_no');
      let modelSelected = await this.cachingService.getDataFromSession(
        'modelSelected'
      );
      let config = await this.cachingService.getDataFromSession('config');
      let buildName = await this.cachingService.getDataFromSession('buildName');

      let userDetails = await this.cachingService.getDataFromLocal(
        'userDetails'
      );
      let visitorInfo = await this.cachingService.getDataFromLocal(
        'visitor_id'
      );
      let emi = await this.cachingService.getDataFromSession('emi_details');
      let carExchange = await this.cachingService.getDataFromSession(
        'exchange_values'
      );
      let extendedWarranty = await this.cachingService.getDataFromSession(
        'extendedWarranty_details'
      );
      let amc_details = await this.cachingService.getDataFromSession(
        'amc_details'
      );
      console.log('amc_details', amc_details);

      console.log('extendedWarranty', extendedWarranty);

      let configPacks = config.addedAccessoriesPacks;
      let addedPacks = [];
      for (let index = 0; index < configPacks.length; index++) {
        let packs = {
          accessories_id: configPacks[index].packageId,
          price: configPacks[index].pack_price,
          accessory_type: 'Package',
          quantity: 1,
        };
        addedPacks.push(packs);
      }
      let addedAccessories = config.addedAccessories;
      addedAccessories.push(...addedPacks);
      let device = this.platform.platforms();
      let requestBody = {
        visitor_id: visitorInfo ? visitorInfo : '',
        variant_id: variantDetails.variant_id,
        customer_id: userDetails ? userDetails.customer_id : null,
        build_name: buildName,
        brand_id: variants.brand_id,
        model_id: modelSelected.model_id,
        variant_color_id: config.colorSelected.variant_color_id,
        showroom_price: config.colorSelected.exshowroom_price,
        page: this.activeTab,
        timespent: this.getTimeSpent(),
        accessories: addedAccessories,
        pin_no: pin_no ? pin_no : null,
        device: device[0],
        version_no: 'A',
        browser: this.checkBrowser(),
        source: 'Configurator',
        channel: 'Web configurator',
        status: this.savedStatus,
        pdf_download: this.pdf_download_clicked,
        copy_url_clicked: this.copy_url_clicked,
        ExchangeValues: {
          ExchangeData: carExchange ? carExchange.ExchangeData : null,
          RequestType: 'ThreeDimensionalLead',
          MakeBrand: carExchange ? carExchange.MakeBrand : null,
          MakeYear: carExchange ? carExchange.MakeYear : null,
          MakeModel: carExchange ? carExchange.MakeModel : null,
          MakeVariant: carExchange ? carExchange.MakeVariant : null,
          MakeRegistrationNo: carExchange
            ? carExchange.MakeRegistrationNo
            : null,
          MakeKMSDriven: carExchange ? carExchange.MakeKMSDriven : null,
          MinimumValue: carExchange ? carExchange.MinimumValue : null,
        },
        FinanceOptions: {
          ExShowroomPrice: emi ? emi.ExShowroomPrice : null,
          DownPayment: emi ? emi.DownPayment : null,
          TradeInValue: emi ? emi.TradeInValue : null,
          EstimatedAmountFinanced: emi ? emi.EstimatedAmountFinanced : null,
          TermDuration: emi ? emi.TermDuration : null,
          AnnualPercentageRate: emi ? emi.AnnualPercentageRate : null,
          EstimatedMonthlyPayment: emi ? emi.EstimatedMonthlyPayment : null,
        },
        ExtendedWarrantyInfo: {
          ExtendedWarrantyPrice: extendedWarranty
            ? extendedWarranty.warrantyPrice
            : null,
          VehicleAgeandKmDriven: extendedWarranty?.kmsDrivenOption
            ? extendedWarranty.kmsDrivenOption?.sales_period +
              ' & ' +
              extendedWarranty.kmsDrivenOption?.product_options
            : null,
          vehicle_age: extendedWarranty ? extendedWarranty.vehicleAge : null,
          warranty_date: extendedWarranty
            ? extendedWarranty.purchaseDate
            : null,
        },
        AnnualMaintenanceContractInfo: {
          AnnualMaintenanceContractPrice: amc_details
            ? amc_details.AnnualMaintenanceContractPrice
            : null,
          VehicleAgeandKmDrivenAMC: amc_details
            ? amc_details.VehicleAgeandKmDrivenAMC
            : null,
        },
      };
      if (crudOperation == 'create') {
        requestBody['dealer_code'] = '';
        requestBody['sfdc_source'] = '3D Configurator';
        requestBody['sfdc_channel'] = 'Web Lead';
      }
      if (!pin_no) {
        crudOperation = 'create';
      }
      this.apiService.post(`configuration/${crudOperation}`, requestBody).then(
        async (data: any) => {
          if (data.msg == 'success') {
            if (crudOperation == 'create') {
              let response = data.response;
              this.cachingService.setDataInSession('pin_no', response.pin_no);
              this.cachingService.setDataInLocal(
                'visitor_id',
                response.visitor_id
              );
              this.apiService.logGAEvent(
                'uaevent',
                'pin_no',
                'Click',
                response.pin_no
              );
            }
            let config = await this.cachingService.getDataFromSession('config');
            if (
              config.addedAccessories.length > 0 ||
              config.addedAccessoriesPacks.length > 0
            ) {
              this.addedAccessories = config.addedAccessories;
              this.addedAccessoriesPacks = config.addedAccessoriesPacks;
            }
            this.setConfigData().then((res) => {
              resolve(true);
            });
          }
        },
        (error) => {
          this.widgetsService.toastWithButtons(this.apiErrorMsg, 'danger');
          console.error('Error : ', error);
          reject(false);
        }
      );
    });
  }
  async saveRemoveAccessory(accessory, type) {
    let pin_no = await this.cachingService.getDataFromSession('pin_no');
    let requestBody = {
      pin_no: pin_no,
      accessories_id:
        type == 'Accessory' ? accessory.accessories_id : accessory.packageId,
      accessory_type: type,
      price: type == 'Accessory' ? accessory.price : accessory.pack_price,
      quantity: type == 'Accessory' ? accessory.quantity : 1,
    };
    this.widgetsService.showLoader();
    this.apiService.post('configuration/accessory/save', requestBody).then(
      async (data: any) => {
        this.widgetsService.hideLoader();
        if (data.msg == 'success') {
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(this.apiErrorMsg, 'danger');
        console.error('Error : ', error);
      }
    );
  }
  getTimeSpent() {
    let timer = performance.now();
    let counter = Math.round(timer / 1000);
    let sec = counter;
    let hours: any = Math.floor(sec / 3600);
    let minutes: any = Math.floor((sec - hours * 3600) / 60);
    let seconds: any = sec - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    let timeSpent = hours + ':' + minutes + ':' + seconds;
    return timeSpent;
  }

  checkBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else {
      return 'unknown';
    }
  }

  async openBuildNameModal(action) {
    let buttonText: string;
    if (action == 'save') {
      buttonText = 'SAVE & SHARE';
    } else if (action == 'proceedToBook') {
      buttonText = 'SAVE & BOOK';
    }
    const modal = await this.modalController.create({
      component: BuildnameModalComponent,
      componentProps: {
        buttonText: buttonText,
        action: action,
      },
      backdropDismiss: false,
      cssClass: 'one3d-build-name-popup',
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    data ? this.openLoginModal(action) : null;
  }

  async openLoginModal(action) {
    let userDetails = await this.cachingService.getDataFromLocal('userDetails');

    // use firstName & email to verify is user logged in or not
    if (userDetails?.first_name && userDetails?.email) {
      this.saveConfiguration(action);
    } else {
      this.configuration('update');
      const modal = await this.modalController.create({
        component: LoginComponent,
        componentProps: {
          contentData: {
            header: 'SAVE & SHARE',
            msgContent:
              'Saving your build will ensure you have easy access to it, anywhere, anytime.',
            from: 'cta',
            action: action,
          },
        },
        backdropDismiss: false,
        cssClass: 'one3d-login-modal auto-height',
        animated: false,
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      console.log('data', data);

      if (data == 'success') {
        console.log('save');

        this.saveConfiguration(action);
      }
    }
  }

  async saveConfiguration(action) {
    console.log('saveConfiguration');

    let variantDetails = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    if (action == 'proceedToBook') {
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click',
        'Save and Book'
      );
    }
    let variants = await this.cachingService.getDataFromSession('variants');
    let pin_no = await this.cachingService.getDataFromSession('pin_no');
    let modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );
    let buildName = await this.cachingService.getDataFromSession('buildName');
    let config = await this.cachingService.getDataFromSession('config');
    let utm = await this.cachingService.getDataFromSession('utm_params');
    let visitorInfo = await this.cachingService.getDataFromLocal('visitor_id');
    let configPacks = config.addedAccessoriesPacks;
    let addedPacks = [];
    let oldConfiguration = await this.cachingService.getDataFromSession(
      'oldConfiguration'
    );

    let userDetails = await this.cachingService.getDataFromLocal('userDetails');

    let emi = await this.cachingService.getDataFromSession('emi_details');
    let carExchange = await this.cachingService.getDataFromSession(
      'exchange_values'
    );
    let extendedWarranty = await this.cachingService.getDataFromSession(
      'extendedWarranty_details'
    );
    let amc_details = await this.cachingService.getDataFromSession(
      'amc_details'
    );
    for (let index = 0; index < configPacks.length; index++) {
      let packs = {
        accessories_id: configPacks[index].packageId,
        price: configPacks[index].pack_price,
        accessory_type: 'Package',
        quantity: 1,
      };
      addedPacks.push(packs);
    }
    let device = this.platform.platforms();
    let addedAccessories = config.addedAccessories;
    addedAccessories.push(...addedPacks);
    let requestBody = {
      visitor_id: visitorInfo ? visitorInfo : '',
      customer_id: userDetails.customer_id,
      variant_id: variantDetails.variant_id,
      build_name: buildName,
      brand_id: variants.brand_id,
      model_id: modelSelected.model_id,
      variant_color_id: config.colorSelected.variant_color_id,
      showroom_price: config.colorSelected.exshowroom_price,
      page: this.activeTab,
      timespent: this.getTimeSpent(),
      accessories: addedAccessories,
      pin_no: pin_no ? pin_no : null,
      device: device[0],
      browser: this.checkBrowser(),
      UTMCampaign: utm ? utm.utm_campaign : null,
      UTMSource: utm ? utm.utm_medium : null,
      UTMContent: utm ? utm.utm_content : null,
      UTMMedium: utm ? utm.utm_source : null,
      isUpdate: oldConfiguration ? 'yes' : 'no',
      channel: 'Web configurator',
      ExchangeValues: {
        ExchangeData: carExchange ? carExchange.ExchangeData : null,
        RequestType: 'ThreeDimensionalLead',
        MakeBrand: carExchange ? carExchange.MakeBrand : null,
        MakeYear: carExchange ? carExchange.MakeYear : null,
        MakeModel: carExchange ? carExchange.MakeModel : null,
        MakeVariant: carExchange ? carExchange.MakeVariant : null,
        MakeRegistrationNo: carExchange ? carExchange.MakeRegistrationNo : null,
        MakeKMSDriven: carExchange ? carExchange.MakeKMSDriven : null,
        MinimumValue: carExchange ? carExchange.MinimumValue : null,
      },
      FinanceOptions: {
        ExShowroomPrice: emi ? emi.ExShowroomPrice : null,
        DownPayment: emi ? emi.DownPayment : null,
        TradeInValue: emi ? emi.TradeInValue : null,
        EstimatedAmountFinanced: emi ? emi.EstimatedAmountFinanced : null,
        TermDuration: emi ? emi.TermDuration : null,
        AnnualPercentageRate: emi ? emi.AnnualPercentageRate : null,
        EstimatedMonthlyPayment: emi ? emi.EstimatedMonthlyPayment : null,
      },
      ExtendedWarrantyInfo: {
        ExtendedWarrantyPrice: extendedWarranty
          ? extendedWarranty.warrantyPrice
          : null,
        VehicleAgeandKmDriven: extendedWarranty?.kmsDrivenOption
          ? extendedWarranty.kmsDrivenOption?.sales_period +
            ' & ' +
            extendedWarranty.kmsDrivenOption?.product_options
          : null,
        vehicle_age: extendedWarranty ? extendedWarranty.vehicleAge : null,
        warranty_date: extendedWarranty ? extendedWarranty.purchaseDate : null,
      },
      AnnualMaintenanceContractInfo: {
        AnnualMaintenanceContractPrice: amc_details
          ? amc_details.AnnualMaintenanceContractPrice
          : null,
        VehicleAgeandKmDrivenAMC: amc_details
          ? amc_details.VehicleAgeandKmDrivenAMC
          : null,
      },
    };
    this.apiService.post('configuration/save', requestBody).then(
      async (data: any) => {
        this.widgetsService.hideLoader();
        if (data.msg == 'success') {
          if (action == 'save') {
            this.openShareModal();
          } else if (action == 'proceedToBook') {
            this.proceedToBook();
          }
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(this.apiErrorMsg, 'danger');
        console.error('Error : ', error);
      }
    );
  }
  async openShareModal() {
    const modal = await this.modalController.create({
      component: SaveSharePopupComponent,
      backdropDismiss: false,
      cssClass: 'one3d-save-share-popup',
    });

    await modal.present();
  }
  async proceedToBook() {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'Redirect to BMO page'
    );

    let config = await this.cachingService.getDataFromSession('config');
    let variantDetails = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    let modelDetails = await this.cachingService.getDataFromSession(
      'model_details'
    );
    let pin_no = await this.cachingService.getDataFromSession('pin_no');

    let ifIframe = this.inIframe();
    let BASE_URL: any;
    if (ifIframe) {
      BASE_URL = this.getParentOrigin();
    } else {
      let hostName = window.location.hostname;
      switch (hostName) {
        case 'visualizer.jeep-india.com':
          BASE_URL = 'https://configurator.jeep-india.com';
          break;
        case 'staging-jeep-vs.one3d.in':
          BASE_URL = 'https://staging-jeep-cc.one3d.in';
          break;
        case 'localhost':
          BASE_URL = `http://${window.location.host}`;
          break;
        default:
          break;
      }
    }
    console.log('BASE_URL', BASE_URL);

    let bookingURL =
      BASE_URL +
      '/bmo-page.html#/build/powertrain/' +
      this.zipCode +
      '/' +
      modelDetails.commercial_model_code +
      '/' +
      variantDetails.variant_cpos +
      '/' +
      config.colorSelected.option_code +
      `?pin=${pin_no}`;
    window.open(bookingURL, '_parent');
  }

  // async openPrivacyPolicy() {
  //   const modal = await this.modalController.create({
  //     component: PrivacyPolicyComponent,
  //     animated: false,
  //     backdropDismiss: false,
  //     cssClass: 'one3d-privacy-policy-modal',
  //   });

  //   await modal.present();
  // }

  // for jeep-japan open privacy policy page
  openPrivacyPolicy() {
    let policyUrl = 'https://www.jeep-japan.com/privacy.html';
    window.open(policyUrl, '_blank');
  }

  getParentOrigin() {
    const locationAreDisctint = window.location !== window.parent.location;
    const parentOrigin = (
      (locationAreDisctint ? document.referrer : document.location) || ''
    ).toString();

    if (parentOrigin) {
      return new URL(parentOrigin).origin;
    }

    const currentLocation = document.location;

    if (
      currentLocation.ancestorOrigins &&
      currentLocation.ancestorOrigins.length
    ) {
      return currentLocation.ancestorOrigins[0];
    }

    return '';
  }
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  clearCacheForFinanceCalculators() {
    this.cachingService.setDataInSession('emi_details', null);
    this.cachingService.setDataInSession('extendedWarranty_details', null);
    this.cachingService.setDataInSession('exchange_values', null);
    this.cachingService.setDataInSession('amc_details', null);
  }
  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }

  /**
   * To get the translation while dynamically rendering html
   * @param label pass the key of text whose translation needed (please make sure that key exists in ja.json)
   * @returns translated text
   */
  getTranslation(label) {
    return new Promise((resolve) => {
      this.translate.get(label).subscribe((translation: any) => {
        resolve(translation);
      });
    });
  }
}
