import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global/global.service';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-extended-warranty',
  templateUrl: './extended-warranty.component.html',
  styleUrls: ['./extended-warranty.component.scss'],
})
export class ExtendedWarrantyComponent implements OnInit {
  minValueDate: any;
  maxValueDate: any;
  purchaseDate: any;
  vehicleAge: any;
  modelId: any;
  warrantyPrice: Number;
  isDateSelected = false;
  isKmsDrivenSelected = false;
  allOptionCategory: any;
  kmsDrivenOption: any;

  constructor(
    private apiService: ApiService,
    private cachingService: CachingService,
    private modalController: ModalController,
    public globalService: GlobalService,
    private widgetsService: WidgetsService
  ) {}

  ngOnInit() {
    var today = new Date();
    this.maxValueDate = this.formatDate(today);

    var threeYearAgo = new Date(
      today.getFullYear() - 3,
      today.getMonth(),
      today.getDate() + 1
    );
    this.minValueDate = this.formatDate(threeYearAgo);

    this.checkIfStoreInSession();
  }

  async checkIfStoreInSession() {
    let extendedWarranty = await this.cachingService.getDataFromSession(
      'extendedWarranty_details'
    );
    // if (extendedWarranty?.purchaseDate) {
    //   this.purchaseDate = extendedWarranty.purchaseDate;
    //   this.dateChange();
    // }
    if (extendedWarranty?.kmsDrivenOption?.warranty_price) {
      this.purchaseDate = extendedWarranty.purchaseDate;
      this.vehicleAge = extendedWarranty.vehicleAge;
      this.warrantyPrice = extendedWarranty?.kmsDrivenOption?.warranty_price;
      this.kmsDrivenOption = extendedWarranty.kmsDrivenOption;
      this.dateChange('alreadyAdded');
    } else {
      const today = new Date();
      this.purchaseDate = this.formatDate(today);
      this.dateChange();
    }
  }

  formatDate(date: Date) {
    return date.toLocaleDateString('en-CA');
  }

  async dateChange(alreadyAdded?) {
    this.isKmsDrivenSelected = false;
    this.isDateSelected = false;

    let modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );

    // var today = new Date();
    // var dateSelected = new Date(this.purchaseDate);
    // var threeYearAgo = new Date(
    //   today.getFullYear() - 3,
    //   today.getMonth(),
    //   today.getDate() + 1
    // );

    // if (
    //   dateSelected.getTime() < threeYearAgo.getTime() ||
    //   dateSelected.getTime() > today.getTime()
    // ) {
    //   this.widgetsService.toastWithButtons(
    //     'Date must be in the range of 3 years.',
    //     'danger'
    //   );
    //   return;
    // }
    // To calculate the time difference of two dates
    // var difference_In_Time = today.getTime() - dateSelected.getTime();

    // To calculate the no. of days between two dates
    // this.vehicleAge = Math.floor(difference_In_Time / (1000 * 3600 * 24));

    let requestBody = {
      model_id: modelSelected.model_id,
      type: 'EW',
    };

    this.widgetsService.showLoader();
    this.apiService.post('configuration/extended-warranty', requestBody).then(
      async (res: any) => {
        this.widgetsService.hideLoader();
        if (res.msg == 'success') {
          this.isDateSelected = true;
          alreadyAdded ? (this.isKmsDrivenSelected = true) : null;
          this.allOptionCategory = res.response;
        } else {
          this.widgetsService.toastWithButtons(
            typeof res.response == 'string'
              ? res.response
              : 'Something went wrong!',
            'danger'
          );
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons('Something went wrong!', 'danger');
      }
    );
  }

  selectDrivenCategory(optionList) {
    this.isKmsDrivenSelected = true;
    this.kmsDrivenOption = optionList[0];
    this.warrantyPrice = optionList[0].warranty_price;
  }

  addToSummary() {
    let extendedWarranty_details = {
      kmsDrivenOption: this.kmsDrivenOption,
      warrantyPrice: this.warrantyPrice,
      vehicleAge: this.kmsDrivenOption.product_year,
      purchaseDate: this.purchaseDate,
      mileage: this.kmsDrivenOption.product_kms,
    };

    this.cachingService.setDataInSession(
      'extendedWarranty_details',
      extendedWarranty_details
    );
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'add extended warranty to summary'
    );
    this.modalController.dismiss('success');

    // this.closeWarranty();
  }

  closeWarranty() {
    this.modalController.dismiss();
  }

  skipWarranty() {
    this.modalController.dismiss();
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'skip extended warranty'
    );
  }
}
