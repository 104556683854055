import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { encode, decode } from 'ee-crypto-encode-decode';
import { environment } from 'src/environments/environment';
import { CachingService } from '../cache/caching.service';

declare var window;
var apiVersion = 'v2/'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private isEncrypted = false;
  constructor(
    private httpClient: HttpClient,
    private cachingService: CachingService
  ) {}

  /**
   *
   * @returns
   * The code is trying to authenticate the user by sending them a token.
   * It's also using sessionStorage to store the access-token.
   */
  authenticate() {
    return new Promise((resolve, reject) => {
      let json = {
        username:
          'U2FsdGVkX18jrGBdGbjQy3Ox8Jr5gZEC1kH7jGwWj6uMFFbCcm69p+xgnlVHBQVD',
        password:
          'U2FsdGVkX1/jc6H5q8t+M59jzsrUovwvoazpl6vbV5rWww3YOf2iRHQcV2uLgCKe',
      };
      let reqBody = this.isEncrypted ? encode(json) : json;
      return this.httpClient
        .post(environment.apiUrl + 'api/login ', reqBody)
        .toPromise()
        .then(
          (data: any) => {
            sessionStorage.setItem('access-token', data.token);
            resolve(true);
          },
          (error) => {}
        );
    });
  }

  /**
   *
   * @param token The code is trying to validate a token.
   * The code is sending the token and an encrypted body to the server.
   * If the server responds with success, then it will authenticate itself.
   * Otherwise, it will log any errors that occurred in its console and continue on as normal.
   * @returns
   */
  validateToken(token) {
    let reqBody = {
      token: token,
    };
    reqBody = this.isEncrypted ? encode(reqBody) : reqBody;
    return this.httpClient
      .post(environment.apiUrl + 'api/verify-token', reqBody)
      .toPromise()
      .then(
        (res: any) => {
          if (res.msg != 'success') {
            this.authenticate();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  /**
   * The code is a promise that will return the data from the JSON file.
   * @param jsonName The Json to fetch
   * @returns
   */
  getDataFromJSON(jsonName: String) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any[]>(`assets/JSON/${jsonName}.json`, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            resolve(response.body);
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * @param endPoint
   * @param requestBody
   * The code is a function that takes in an endpoint and request body.
   * It then returns a promise which is resolved with the response or rejected with any error.
   * The code first checks if the request is encrypted by checking if it's property "isEncrypted" is true.
   * If so, it will encode the request body before sending to the server and return a promise that resolves with JSON data parsed from the response body.
   * Otherwise, it sends out without encoding and resolves when successful or rejects on errors
   * @returns
   */
  post(endPoint: string, requestBody: any) {
    let request = this.isEncrypted ? encode(requestBody) : requestBody;
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(environment.apiUrl + 'api/'+ apiVersion + endPoint, request, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            if (this.isEncrypted) {
              resolve(JSON.parse(decode(response.body)));
            } else {
              resolve(response.body);
            }
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * @param endpoint The code is a function that downloads the file from an endpoint.
   * @returns
   */
  download(endpoint) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };

    return this.httpClient.get(
      environment.apiUrl + 'api/'+ apiVersion + endpoint,
      httpOptions
    );
  }
  /**
   * The code is a function that takes in an event and then pushes the data to the data layer.
   * The code is called when there is an event on the window object.
   * It will be called when the user clicks on an event.
   * @param event The first parameter of the function is an event object which contains information about what happened in the application.
   * @param eventCategory The second parameter is a string representing the category of events that this event falls under.
   * @param eventAction The third parameter is a string representing what action was taken by the user.
   * @param eventLabel The final parameter is a string with some text describing what happened to help identify why this event occurred.
   */
  logGAEvent(event, eventCategory, eventAction, eventLabel) {
    window.dataLayer.push({
      event: event,
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
    });
  }
  async visualizerAnalytics(eventName) {
    if (window.location.pathname.includes('configurator')) return;
    let pin_no = await this.cachingService.getDataFromSession('p_vs');
    let request = {
      pin_no: pin_no,
      col_name: eventName,
      col_value: 'Yes',
    };
    this.post('configuration/visualizer/save-analytics', request)
      .then((res) => {
        console.log('Visualizer analytics captured');
      })
      .catch((err) => {
        console.error('Error ', err);
      });
  }
}
