import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccessoriesService } from 'src/app/services/Accessories/accessory.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { ApiService } from 'src/app/services/API/api.service';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { EmiComponent } from '../emi/emi.component';
import { EventService } from 'src/app/services/Events/events.service';
import { ExtendedWarrantyComponent } from '../extended-warranty/extended-warranty.component';
import { CarExchangeComponent } from '../car-exchange/car-exchange.component';
import { AmcComponent } from '../amc/amc.component';
import { SummaryBreakupComponent } from '../summary-breakup/summary-breakup.component';

@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  @ViewChild(SummaryBreakupComponent) summaryBreakup: SummaryBreakupComponent;

  modelSpecifications: any;
  accessories = [];
  accessoryPackages = [];
  totalPrice: number;
  emiDetails: any;
  carExchange: any;
  extendedWarrantyDetails: any;
  mspDetails: any;
  applyHeightForCompatibility = false;
  constructor(
    private cachingService: CachingService,
    public accessoriesService: AccessoriesService,
    public globalService: GlobalService,
    private apiService: ApiService,
    private modalController: ModalController,
    private events: EventService
  ) {}

  ngOnInit() {
    let browser = this.checkBrowser();
    if (browser.name == 'Safari' && browser.version < '15') {
      this.applyHeightForCompatibility = true;
    }

    this.getSpecifications();
    // this.events.publish('events', { openSummary: null });
  }
  checkBrowser() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  ngOnDestroy(): void {
    // this.events.publish('events', { closeSummary: null });
  }

  async getSpecifications() {
    let config = await this.cachingService.getDataFromSession('config');
    let modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );
    this.modelSpecifications = [
      {
        label: 'MODEL',
        value: modelSelected.model_name,
        display: true,
      },
      {
        label: 'VARIANT',
        value: config.selectedVariant.version_name,
        display: true,
      },
      {
        label: 'COLOR',
        value: config.colorSelected.color_name,
        display: true,
      },
      {
        label: 'TRANSMISSION & DRIVE',
        value:
          config.selectedVariant.transmission_type +
          ' ' +
          config.selectedVariant.transmission_code,
        display: true,
      },
      {
        label: await this.globalService.getTranslation('EX-SHOWROOM PRICE'),
        value:
          this.globalService.currencyFormatterIN(
            config.colorSelected.exshowroom_price
          ) + ' ' + await this.globalService.getTranslation('₹') ,
        class: 'bold',
        display: true,
      },
    ];

    this.arrangeAccessories();
    this.getEMIDetails();
    this.getExtendedWarranty();
    this.getCarExchange();
    this.getAMC();
  }
  async getEMIDetails() {
    let emi = await this.cachingService.getDataFromSession('emi_details');
    if (emi?.EstimatedMonthlyPayment) {
      this.emiDetails = emi;
    } else {
      this.emiDetails = undefined;
    }
  }

  async getExtendedWarranty() {
    let extendedWarranty = await this.cachingService.getDataFromSession(
      'extendedWarranty_details'
    );
    if (extendedWarranty?.warrantyPrice) {
      this.extendedWarrantyDetails = extendedWarranty;
      console.log('extendedWarrantyDetails', this.extendedWarrantyDetails);
    } else {
      this.extendedWarrantyDetails = undefined;
    }
    this.events.publish('events', { updatedWarrantyCost: null });
  }
  async getAMC() {
    let amc_details = await this.cachingService.getDataFromSession(
      'amc_details'
    );
    console.log('amc_details', amc_details);
    this.mspDetails = amc_details;
  }
  async getCarExchangeFromAPi() {
    const pin_no = await this.cachingService.getDataFromSession('pin_no');
    const requestBody = {
      pin_no: pin_no,
    };
    this.apiService
      .post('configuration/get-exchange-values', requestBody)
      .then((res: any) => {
        if (res.msg == 'success') {
          this.cachingService.setDataInSession(
            'exchange_values',
            res.response[0]
          );
          console.log(res.response[0]);
          this.getCarExchange();
        } else {
          console.error('Exchange Values Error ');
        }
      })
      .catch((err) => {
        console.error('Exchange Values: ', err);
      });
  }

  async getCarExchange() {
    let carExchange = await this.cachingService.getDataFromSession(
      'exchange_values'
    );
    if (carExchange?.MinimumValue) {
      this.carExchange = carExchange;
    } else {
      this.carExchange = null;
    }
  }

  async arrangeAccessories() {
    let config = await this.cachingService.getDataFromSession('config');
    this.accessories = [];
    this.accessoryPackages = config.addedAccessoriesPacks;
    const accessoryGroups = Array.from(
      new Set(config.addedAccessories.map((item) => item.category))
    );
    for (let index = 0; index < accessoryGroups.length; index++) {
      let arr = config.addedAccessories.filter((el) => {
        return el.category == accessoryGroups[index];
      });
      let arrSortData = {
        groupName: accessoryGroups[index],
        accessories: this.globalService.sortByKey(arr, 'price'),
      };
      this.accessories.push(arrSortData);
    }
  }
  async removeAccessoryConfirmation(accessory, group) {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: 'REMOVE ACCESSORY?',
          content: `"${accessory.marketing_name}"を取り外しますか?`,
          cancelText: 'CANCEL',
          confirmText: 'CONFIRM',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    data ? this.removeAccessory(accessory, group) : null;
  }
  removeAccessory(accessory, group) {
    this.accessoriesService.removeAccessory(accessory, 'fromSummary');
    let index = this.accessories.findIndex(
      (element) => element.groupName === group
    );
    let accessoriesList = this.accessories[index].accessories;
    accessoriesList = accessoriesList.filter((element) => {
      return element.accessories_id != accessory.accessories_id;
    });
    this.accessories[index].accessories =
      accessoriesList.length > 0
        ? accessoriesList
        : this.handleEmptyAccessory(group);
    this.summaryBreakup.getTotalPrice();
  }

  handleEmptyAccessory(group) {
    this.accessories = this.accessories.filter((element) => {
      return element.groupName != group;
    });
  }

  closeSummary() {
    this.modalController.dismiss();
  }
  closeAndOpenAccessories() {
    this.modalController.dismiss({ data: 'openAccessories' });
  }
  saveConfiguration() {
    // for jeep-japan show share modal instead of buildName modal
    this.globalService.openShareModal();
    this.globalService.savedStatus = 'saved'; //to update if this action is clicked or not
    this.globalService.configuration('update');
    this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'Save & Share');
  }

  // for jeep-japan instead of booking have to redirect to request quote url
  proceedToBook() {
    // this.globalService.openBuildNameModal('proceedToBook');

    // Request Quote CTA link for jeep-japan
    let requestQuoteUrl = "https://jeep-real.jp/inline/estimate/";
    window.open(requestQuoteUrl, '_blank');

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'Request a quote'
    );
  }

  async showEmiCalculator() {
    this.globalService.activeTab = 'emi';
    const modal = await this.modalController.create({
      component: EmiComponent,
      backdropDismiss: false,
      animated: false,
      cssClass: 'one3d-sidePanelModal-summary',
    });

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'EMI calculator'
    );
    await modal.present();
    const { data } = await modal.onDidDismiss();
    this.globalService.activeTab = 'summary';
    if (data == 'success') {
      this.getEMIDetails();
      this.globalService.configuration('update');
    }
  }

  async showExtendedWarranty() {
    this.globalService.activeTab = 'extendedWarranty';
    const modal = await this.modalController.create({
      component: ExtendedWarrantyComponent,
      backdropDismiss: false,
      animated: false,
      cssClass: 'one3d-sidePanelModal-summary',
    });

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'Extended Warranty'
    );
    await modal.present();
    const { data } = await modal.onDidDismiss();
    this.globalService.activeTab = 'summary';
    if (data == 'success') {
      this.getExtendedWarranty();
      this.globalService.configuration('update');
    }
  }
  async showAmcCalculator() {
    this.globalService.activeTab = 'amc';
    const modal = await this.modalController.create({
      component: AmcComponent,
      backdropDismiss: false,
      animated: false,
      cssClass: 'one3d-sidePanelModal-summary',
    });

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'AMC Calculator'
    );
    await modal.present();
    const { data } = await modal.onDidDismiss();
    this.globalService.activeTab = 'summary';
    if (data == 'success') {
      this.getAMC();
      this.globalService.configuration('update');
    }
  }
  async showCarExchange() {
    this.globalService.activeTab = 'carExchange';
    const modal = await this.modalController.create({
      component: CarExchangeComponent,
      backdropDismiss: false,
      animated: false,
      cssClass: 'one3d-sidePanelModal-summary',
    });

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Redirection::Internal',
      'Car Exchange'
    );
    await modal.present();
    const { data } = await modal.onDidDismiss();
    this.globalService.activeTab = 'summary';
    console.log(data);
    if (data == 'success') {
      // this.getCarExchangeFromAPi();
      this.getCarExchange();
      this.globalService.configuration('update');
    }
  }

  /**
   * to remove EMI/Car Exchange/Extended Warranty from summary page
   * @param removeOptionSession : sessionData key for respective removeBtn
   */
  async removeBtn(removeOptionSession, label) {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      componentProps: {
        msg: {
          header: `REMOVE ${label}?`,
          content: `Are you sure you want to delete it? Once deleted it cannot be restored.`,
          cancelText: 'CANCEL',
          confirmText: 'CONFIRM',
        },
      },
      backdropDismiss: false,
      cssClass: 'confirmation-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.cachingService.removeDataFromSession(removeOptionSession);
      if (removeOptionSession == 'emi_details') {
        this.getEMIDetails();
      } else if (removeOptionSession == 'extendedWarranty_details') {
        this.getExtendedWarranty();
      } else if (removeOptionSession == 'exchange_values') {
        this.getCarExchange();
      } else if (removeOptionSession == 'amc_details') {
        this.getAMC();
      }
      this.globalService.configuration('update');
    }
  }
}
